import { createStyles, makeStyles } from "@material-ui/core";
import theme from "../../../../../layouts/theme";
import {
  emoji,
  stepExplainer,
  verticalWrapper,
} from "../BeschlussInputWho/BeschlussInputWhoStyles";

export const useBeschlussInputVotingModeStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(1),
    },
    switchGroupWrapper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    stepExplainer: { ...stepExplainer },
    verticalWrapper: { ...verticalWrapper },
    emoji: { ...emoji },
    question: {
      marginBottom: 0,
    },
    questionWrapper: {
      marginBottom: theme.spacing(0.5),
      display: "flex",
      flexDirection: "row",
      columnGap: theme.spacing(1),
      alignItems: "center",
    },
  })
);
