import { useCallback, useContext, useEffect, useRef } from "react";
import { useSpring } from "react-spring";
import { templateVariableKey } from "../../../../helpers/general/templates/templateVariableKey";
import { EphemeralUserInputContext } from "../ephemeralUserInput/EphemeralUserInputContext";
import { templateVariableInputAnimation as animation } from "./TemplateVariableInputAnimation";
import {
  TemplateVariableInputProps,
  TemplateVariableInputViewModel,
} from "./TemplateVariableInputInterfaces";

export const useTemplateVariableInputViewModel = (
  props: TemplateVariableInputProps
): TemplateVariableInputViewModel => {
  const key = templateVariableKey(props.variable);

  const [animatedDivStyles, api] = useSpring(() => ({
    config: animation.springConfig,
    from: animation.initial,
  }));

  const startFocusAnimation = useCallback(() => {
    api.start({
      to: animation.animationKeyframes,
    });
  }, [api]);

  const { focusVariable, unFocusVariable, externallyFocusedVariables } =
    useContext(EphemeralUserInputContext);

  const externalFocusCount = externallyFocusedVariables?.[key] || 0;

  const onFocus = useCallback(() => focusVariable(key), [focusVariable, key]);

  const inputRef = useRef<HTMLInputElement>(null);

  const focusExternally = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    // Don't run on mount.
    if (!externalFocusCount) {
      return;
    }

    focusExternally();
    startFocusAnimation();
  }, [focusExternally, externalFocusCount, startFocusAnimation]);

  return {
    animatedDivStyles,
    inputRef,
    onBlur: unFocusVariable,
    onFocus,
    key,
  };
};
