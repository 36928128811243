import { CSSProperties } from "@material-ui/styles";
import { allHeadlineTags } from "../../../../helpers/styling/allHeadlines";

const leftPadding = `2.2rem`;

const listElementStyles: CSSProperties = {
  position: "relative",
  "&:before": {
    position: "absolute",
    top: 0,
    left: 0,
    content: `attr(data-list-style-prefix) counter(item) attr(data-list-style-suffix)`,
    counterIncrement: "item",
    lineHeight: "1.5rem",
  },
};

const listHeadlineStyles: CSSProperties = {
  position: "relative",
  "&:before": {
    position: "absolute",
    left: 0,
    content: `attr(data-list-style-prefix) counter(item) attr(data-list-style-suffix)`,
    counterIncrement: "item",
  },
};

const allHeadlinesInsideLiElements = allHeadlineTags
  .map((hx) => `& li > ${hx}`)
  .join(",");

/**
 * Styles for list items inside the Beschluss.
 *
 * Takes care of our data-list-style customizations.
 */

export const stylesForListItems: CSSProperties = {
  "& ol, & ul": {
    paddingInlineStart: 0,
  },
  "& ol[data-list-style = 'disc']": {
    listStyle: "disc",
  },
  "& ol[data-list-style = 'circle']": {
    listStyle: "circle",
  },
  "& ol[data-list-style = 'square']": {
    listStyle: "square",
  },
  "& ol[data-list-style = 'lower-latin']": {
    listStyle: "lower-latin",
    paddingInlineStart: leftPadding,
  },
  "& ul[data-list-style = 'none']": {
    listStyle: "none",
    paddingInlineStart: leftPadding,
  },
  "& ol[data-list-style-prefix], & ol[data-list-style-suffix]": {
    counterReset: "item",
    listStyleType: "none",
    // Case 1: attributes added on the li element
    "& li[data-list-style-prefix], & li[data-list-style-suffix]": {
      ...listElementStyles,
      paddingLeft: leftPadding,
    },
    // Case 2: attributes added to the headline
    [allHeadlinesInsideLiElements]: {
      ...listHeadlineStyles,
      paddingLeft: "3rem",
    },
  },
};
