import { useTranslation } from "react-i18next";
import { getRoleName } from "../../../../../data/juristicalData/namesForRoles/getRoleName";
import { CompanyTypeName } from "../../../../../data/vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { GrCase } from "../../../../../interfaces/GrCase";
import { GrNumber } from "../../../../../interfaces/grNumber";
import { useCompanyInfo } from "../../../../../state/companyInfo/companyInfoSlice";
import RVOCoatingWrapper from "../../../../03-organisms/RVOCoatingWrapper/RVOCoatingWrapper";

const BeschlussCoatingHeaderPlaceholder = (): JSX.Element => {
  const { t } = useTranslation();

  const { firma, rechtsform, whoCanDecide } = useCompanyInfo();

  const roleName = whoCanDecide
    ? getRoleName(
        whoCanDecide,
        rechtsform || CompanyTypeName.GmbHDe,
        GrNumber.Plural,
        GrCase.Genitive
      )
    : null;

  const whoPart = roleName ? (
    <>
      <br />

      {`der ${roleName}`}
    </>
  ) : (
    ""
  );
  const firmaPart = firma ? (
    <>
      <br />

      {`der ${firma}`}
    </>
  ) : (
    ""
  );

  return (
    <RVOCoatingWrapper
      title={t("general.beschlussCoating.header.title")}
      whoPart={whoPart}
      firmaPart={firmaPart}
    />
  );
};

export default BeschlussCoatingHeaderPlaceholder;
