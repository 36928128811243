import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { WarningEmoji } from "../../../helpers/clientSide/emojis";
import { useId } from "../../../hooks/useId";
import {
  EmptyUserInput,
  emptyUserInputValue,
} from "../../04-templates/BeschlussGenerator/beschlussGeneratorConstants";
import { RVOSelectProps } from "./RVOSelectInterfaces";
import { useRVOSelectStyles } from "./RVOSelectStyles";

/**
 * A simple select (aka "dropdown") with a higher abstraction level than the MUI default.
 *
 * What it does differently (compared to MUI's Select):
 *  - expect a list of string-like things, e.g. enum values, as options
 *  - wrap inside a FormControl
 *  - add an InputLabel
 *  - render an "empty" option by default (with a standardized value)
 *  - provide a more reasonable onChange event (just exposing the selected value)
 *  - allow for an "itemHasWarning" callback
 */
const RVOSelect = <OptionType extends string>(
  props: RVOSelectProps<OptionType>
): JSX.Element => {
  const classes = useRVOSelectStyles();
  const id = useId();
  const hasWarningFn = props.itemHasWarning || (() => false);

  return (
    <FormControl
      className={props.className}
      fullWidth={true}
      variant="outlined"
    >
      <InputLabel id={id}>{props.label}</InputLabel>

      <Select
        disabled={props.disabled}
        labelId="rechtsform-select-label"
        variant="outlined"
        className={classes.selectWrapper}
        value={props.value || emptyUserInputValue}
        label={props.label}
        onChange={(e) => {
          props.onChange(e.target.value as OptionType | EmptyUserInput);
        }}
      >
        <MenuItem key="empty-select" value={emptyUserInputValue}>
          {props.emptyItemLabel}
        </MenuItem>

        {props.options.map((option) => {
          const hasWarning = hasWarningFn(option);

          const label = props.renderOption
            ? props.renderOption(option)
            : option;

          return (
            <MenuItem
              className={classes.optionWrapper}
              value={option}
              key={option}
            >
              {label}

              {hasWarning && <WarningEmoji />}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default RVOSelect;
