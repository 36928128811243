export const templateVariableInputAnimation = (() => {
  const initial = {
    transform: "scale(1.00)",
  };

  const animationKeyframes = [
    {
      transform: "scale(1.07)",
    },
    initial,
  ];

  const springConfig = {
    mass: 1,
    tension: 240,
    friction: 10,
    precision: 0.01,
  };

  return {
    initial,
    animationKeyframes,
    springConfig,
  };
})();
