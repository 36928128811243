import { Box, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ComponentWithoutPropsOrChildren } from "@nvon/react-toolbox";
import { useTranslation } from "react-i18next";
import { SwitchValue } from "../../../../../data/juristicalData/votingModes/votingModeInterfaces";
import { votingModeSwitchEntries } from "../../../../../data/juristicalData/votingModes/votingModeSwitchEntries";
import {
  useSetSwitchValue,
  useSwitchValues,
} from "../../../../../state/votingMode/votingModeSlice";
import RVOHelpButton from "../../../../02-molecules/RVOHelpButton/RVOHelpButton";
import { useBeschlussInputVotingModeStyles } from "./BeschlussInputVotingModeStyles";

const BeschlussInputVotingMode: ComponentWithoutPropsOrChildren = () => {
  const { t } = useTranslation();
  const classes = useBeschlussInputVotingModeStyles();

  const setSwitchValue = useSetSwitchValue();
  const switchValues = useSwitchValues();

  return (
    <Box className={classes.wrapper}>
      {votingModeSwitchEntries.map(([switchKey, switchValue]) => {
        const isRelevant = switchValue.isRelevant(switchValues);
        return (
          <Box
            key={switchKey}
            style={{
              // Todo: animate
              display: isRelevant ? "block" : "none",
            }}
            className={classes.switchGroupWrapper}
          >
            <div className={classes.questionWrapper}>
              <Typography
                variant="h4"
                component="h3"
                className={classes.question}
              >
                {switchValue.question(t)}
              </Typography>

              <RVOHelpButton
                helpTitle={switchValue.helpTitle(t)}
                helpText={switchValue.helpText(t)}
              />
            </div>

            <ToggleButtonGroup
              value={switchValues[switchKey]}
              exclusive={true}
              onChange={(e, newValue: SwitchValue) =>
                setSwitchValue(switchKey, newValue)
              }
              aria-label="text alignment"
            >
              <ToggleButton value={true} aria-label="left aligned">
                {t("general.yes")}
              </ToggleButton>

              <ToggleButton value={false} aria-label="left aligned">
                {t("general.no")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        );
      })}
    </Box>
  );
};

export default BeschlussInputVotingMode;
