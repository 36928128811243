import { createStyles, makeStyles } from "@material-ui/core";
import {
  beschlussPreviewHeadlineMarginTop,
  beschlussPreviewPadding,
  beschlussPreviewPaddingBetween,
} from "../../beschlussGeneratorConstants";
import { beschlussGeneratorMobileBreakpoint } from "../../BeschlussGeneratorViewModel";

export const useBeschlussCoatingHeaderStyles = makeStyles((theme) =>
  createStyles({
    votingModeWrapper: {
      padding: theme.spacing(
        beschlussPreviewPadding,
        beschlussPreviewPadding,
        beschlussPreviewPaddingBetween,
        beschlussPreviewPadding
      ),
      width: "100%",
      margin: "0 0",
      textAlign: "left",
      "& h2:first-of-type": {
        marginTop: theme.spacing(beschlussPreviewHeadlineMarginTop),
        textAlign: "center",
      },
      [theme.breakpoints.down(beschlussGeneratorMobileBreakpoint)]: {
        padding: theme.spacing(
          beschlussPreviewPadding,
          beschlussPreviewPaddingBetween,
          beschlussPreviewPaddingBetween,
          beschlussPreviewPaddingBetween
        ),
      },
    },
  })
);
