import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUserInputOnCurrentVorlageOrThrow } from "../../../../../state/beschlussVorlage/beschlussVorlageSlice";
import { emptyUserInputValue } from "../../beschlussGeneratorConstants";
import BeschlussInputAntrag from "../BeschlussInputAntrag/BeschlussInputAntrag";
import {
  BeschlussInputStepHook,
  StepStatus,
} from "../BeschlussInputInterfaces";

export const useBeschlussInputAntragStep: BeschlussInputStepHook = () => {
  const { t } = useTranslation();

  const data = useUserInputOnCurrentVorlageOrThrow();

  const status = useMemo(
    () =>
      Object.values(data).every((input) => input !== emptyUserInputValue)
        ? StepStatus.Complete
        : StepStatus.Open,
    [data]
  );

  const label = useMemo(() => {
    return status === StepStatus.Open
      ? t("vorlagen.dataInput")
      : t("vorlagen.dataInputComplete");
  }, [status, t]);

  const strings = t("inputSteps.who", { returnObjects: true });

  return useMemo(
    () => ({
      description: strings.description,
      callToAction: strings.callToAction,
      incompleteWarning: strings.incompleteWarning,
      label,
      status,
      component: BeschlussInputAntrag,
    }),
    [
      label,
      status,
      strings.callToAction,
      strings.description,
      strings.incompleteWarning,
    ]
  );
};
