import { createStyles, makeStyles } from "@material-ui/core";

export const useBeschlussGeneratorAppPromotionOverlayStyles = makeStyles(
  (theme) =>
    createStyles({
      dialog: {
        "& .MuiPaper-root": {
          backgroundColor: theme.palette.secondary.light,
        },
      },
    })
);
