import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RolleInGesellschaft } from "../../../../../common-interfaces/VorlageInterface";
import {
  AdditionalRole,
  additionalRolesForRechtsform,
} from "../../../../../data/juristicalData/namesForRoles/additionalRolesForRechtsform";
import { getRoleName } from "../../../../../data/juristicalData/namesForRoles/getRoleName";
import { GrNumber } from "../../../../../interfaces/grNumber";
import { AnyRole } from "../../../../../interfaces/variousInterfaces";
import {
  useCompanyRechtsform,
  useCompanyWhoCanDecide,
  useSetWhoCanDecide,
} from "../../../../../state/companyInfo/companyInfoSlice";
import RVOSelect from "../../../../01-atoms/RVOSelect/RVOSelect";
import { useRoleIsInvalid } from "./useRoleIsInvalid";

export const BeschlussInputWhoWhoCanDecide = (): JSX.Element => {
  const { t } = useTranslation();

  const whoCanDecide = useCompanyWhoCanDecide();
  const rechtsform = useCompanyRechtsform();

  const roleIsInvalid = useRoleIsInvalid();

  const setWhoCanDecide = useSetWhoCanDecide();

  const decidersLabel = rechtsform
    ? t("companyInfo.whoCanDecide")
    : t("companyInfo.choseRechtsformFirst");

  const possibleRoles: (RolleInGesellschaft | AdditionalRole)[] = useMemo(
    () => [
      ...Object.values(RolleInGesellschaft),
      ...(rechtsform ? additionalRolesForRechtsform[rechtsform] : []),
    ],
    [rechtsform]
  );

  const getRoleLabel = useCallback(
    (role: AnyRole): string =>
      getRoleName(role, rechtsform, GrNumber.Plural) || role,
    [rechtsform]
  );

  return (
    <RVOSelect
      disabled={!rechtsform}
      emptyItemLabel={t("general.beschlussCoating.header.chooseRolle")}
      value={rechtsform ? whoCanDecide : null}
      label={decidersLabel}
      onChange={(newVal) => setWhoCanDecide(newVal || null)}
      options={possibleRoles}
      renderOption={getRoleLabel}
      itemHasWarning={roleIsInvalid}
    />
  );
};
