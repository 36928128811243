import { Box, Button, Typography } from "@material-ui/core";
import PhonelinkLockOutlinedIcon from "@material-ui/icons/PhonelinkLockOutlined";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { RocketEmoji } from "../../../../helpers/clientSide/emojis";
import RVOModal from "../../../02-molecules/RVOModal/RVOModal";
import BeschlussInput from "../BeschlussInput/BeschlussInput";
import { useBeschlussGeneratorWelcomeOverlayStyles } from "./BeschlussGeneratorWelcomeOverlayStyles";
import { useBeschlussGeneratorWelcomeOverlayViewModel } from "./BeschlussGeneratorWelcomeOverlayViewModel";

const BeschlussGeneratorWelcomeOverlay = (): JSX.Element => {
  const classes = useBeschlussGeneratorWelcomeOverlayStyles();
  const { t } = useTranslation();

  const {
    isOverlayOpen,
    closeOverlay,
    showStepOneWarning,
    onStepChange,
    steps,
    allStepsCompleted,
    proceedToBeschluss,
  } = useBeschlussGeneratorWelcomeOverlayViewModel();

  return (
    <RVOModal
      emoji={RocketEmoji}
      className={classes.modal}
      isOpen={isOverlayOpen}
      onClose={closeOverlay}
      title={t("welcomeOverlay.letsStart")}
    >
      <Box className={classes.hints}>
        <Typography variant="body1" className={classes.dataIsSafe}>
          <PhonelinkLockOutlinedIcon />

          {t("welcomeOverlay.dataIsSafe")}
        </Typography>

        {showStepOneWarning && (
          <Alert severity="warning" className={classes.alert}>
            {steps[0] ? steps[0].incompleteWarning : undefined}
          </Alert>
        )}

        <BeschlussInput
          className={classes.input}
          onStepChange={onStepChange}
          overrideSteps={steps}
        />
      </Box>

      <Box className={classes.buttonBox}>
        {allStepsCompleted ? (
          <Button variant="contained" onClick={proceedToBeschluss}>
            {t("general.toTheBeschluss")}
          </Button>
        ) : (
          <Button variant="outlined" onClick={closeOverlay}>
            {t("general.skip")}
          </Button>
        )}
      </Box>
    </RVOModal>
  );
};

export default BeschlussGeneratorWelcomeOverlay;
