import { createStyles, makeStyles } from "@material-ui/core";
import { allNestedHeadlinesSelector } from "../../../../helpers/styling/allHeadlines";
import { important } from "../../../../helpers/styling/important";
import { fontFamilySerif } from "../../../../layouts/theme";
import {
  beschlussPreviewPadding,
  beschlussPreviewPaddingAntrag,
} from "./../beschlussGeneratorConstants";
import { stylesForListItems } from "./stylesForListItems";

export const useBeschlussPreviewStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      height: "100%",
      maxWidth: "100%",
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "&, & *": {
        color: theme.palette.common.black,
      },
      [theme.breakpoints.down("md")]: {
        minHeight: "600px",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "auto",
        display: "block",
      },
    },
    beschlussContent: {
      "& p": { hyphens: "auto", textAlgin: "justify" },
      lineHeight: 2.2,
      padding: theme.spacing(0, beschlussPreviewPaddingAntrag),
      marginBottom: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "100% ",
      ...stylesForListItems,
      [allNestedHeadlinesSelector]: {
        textAlign: "left",
        fontFamily: fontFamilySerif,
        "&.marginTop": {
          mt: 2,
          mb: 1.5,
        },
        "&.marginBottom": {
          mb: 1,
        },
      },
      "& h2": { textAlign: "center" },
      "& h3": {
        fontSize:
          theme.typography.h3.fontSize &&
          important(theme.typography.h3.fontSize),
      },

      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 1.5),
      },
    },
    beschlussContentPdfOutput: {
      lineHeight: important(1.5),
      "& li li": {
        marginTop: theme.spacing(2),
      },
    },
    documentTitleWrapper: {
      padding: theme.spacing(2, beschlussPreviewPadding),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& h2": {
        textAlign: "center",
      },
    },
    "@media print": {
      wrapper: {
        display: "block",
        "& p": {
          background: "transparent",
        },
      },
    },
  })
);
