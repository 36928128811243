import {
  Box,
  Button,
  CircularProgress,
  Grid,
  NoSsr,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Description as DescriptionIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { vorlagenInUse } from "../../../helpers/clientSide/constants";
import { useCurrentVorlage } from "../../../state/beschlussVorlage/beschlussVorlageSlice";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import RVOCtaEmployeeParticipation from "../../01-atoms/RVOCtaEmployeeParticipation/RVOCtaEmployeeParticipation";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import RVOModal from "../../02-molecules/RVOModal/RVOModal";
import { RVOModalSize } from "../../02-molecules/RVOModal/RVOModalInterfaces";
import VorlagenPicker from "../../03-organisms/VorlagenPicker/VorlagenPicker";
import BeschlussFinishedOverlay from "./BeschlussFinishedOverlay/BeschlussFinishedOverlay";
import { BeschlussGeneratorAppPromotionOverlay } from "./BeschlussGeneratorAppPromotionOverlay/BeschlussGeneratorAppPromotionOverlay";
import { BeschlussGeneratorProps } from "./BeschlussGeneratorInterfaces";
import BeschlussGeneratorMobilePreviewButton from "./BeschlussGeneratorMobilePreviewButton/BeschlussGeneratorMobilePreviewButton";
import { useBeschlussGeneratorStyles } from "./BeschlussGeneratorStyles";
import { useBeschlussGeneratorViewModel } from "./BeschlussGeneratorViewModel";
import RVORechtsformWarning from "./BeschlussGeneratorWarnings/RVORechtsformWarning";
import RVORoleWarning from "./BeschlussGeneratorWarnings/RVORoleWarning";
import BeschlussGeneratorWelcomeOverlay from "./BeschlussGeneratorWelcomeOverlay/BeschlussGeneratorWelcomeOverlay";
import BeschlussInput from "./BeschlussInput/BeschlussInput";
import { BeschlussPageHeader } from "./BeschlussPageHeader/BeschlussPageHeader";
import BeschlussPreview from "./BeschlussPreview/BeschlussPreview";
import BeschlussPreviewOnMobile from "./BeschlussPreviewOnMobile/BeschlussPreviewOnMobile";
import { EphemeralUserInputContext } from "./ephemeralUserInput/EphemeralUserInputContext";

/**
 * Component for filling a Vorlage with some data, turning it into a (downloadable) Beschluss.
 */
const BeschlussGenerator = (props: BeschlussGeneratorProps): JSX.Element => {
  const {
    isDrawerOpen,
    hideDrawer,
    handleGeneratePdfClick,
    ephemeralUserInputState,
    beschlussElement,
    isPickerShowing,
    onPickerClose,
    isMobilePreviewShowing,
    showPickerOverlay,
    focusVotingMode,
    toggleIsMobilePreviewShowing,
    seo,
    isGenerating,
    savePdf,
    vorlage,
    isEmployeeParticipationShowing,
    closeEmployeeParticipationModal,
    isCscTemplateShowing,
  } = useBeschlussGeneratorViewModel(props);

  const vorlageIsLoaded = !!useCurrentVorlage();
  const { t } = useTranslation();
  const classes = useBeschlussGeneratorStyles();
  const theme = useTheme();

  const createBeschlussButton = (
    <Button
      disabled={isGenerating}
      variant="contained"
      fullWidth={true}
      onClick={handleGeneratePdfClick}
      endIcon={
        isGenerating ? <CircularProgress size={20} /> : <DescriptionIcon />
      }
    >
      {t("vorlagen.createBeschluss")}
    </Button>
  );

  return (
    <>
      <Helmet title={seo.title}>
        <meta name="description" content={seo.metaDescription} />
      </Helmet>

      <EphemeralUserInputContext.Provider value={ephemeralUserInputState}>
        <BeschlussPageHeader
          onVorlageClick={showPickerOverlay}
          onVotingModeClick={focusVotingMode}
          vorlage={vorlage}
        />

        <NoSsr>
          <RVOModal
            className={classes.pickerModal}
            isOpen={isEmployeeParticipationShowing}
            onClose={closeEmployeeParticipationModal}
            disableClose={true}
            size={RVOModalSize.Large}
            blur={4}
          >
            <Box padding={theme.spacing(4)}>
              <Alert
                severity="warning"
                style={{ marginBottom: theme.spacing(3) }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  <HtmlFromLangFile
                    contentContainingHtml={t(
                      "mitarbeiterbeteiligung.templateWarning"
                    )}
                  />
                </Typography>
              </Alert>

              <RVOCtaEmployeeParticipation learnMoreOnly={true} />
            </Box>
          </RVOModal>

          <RVOModal
            className={classes.pickerModal}
            isOpen={isPickerShowing}
            onClose={onPickerClose}
            size={RVOModalSize.Large}
          >
            <VorlagenPicker allAvailableVorlagen={vorlagenInUse} />
          </RVOModal>

          {!isEmployeeParticipationShowing && !isCscTemplateShowing && (
            <>
              <BeschlussGeneratorWelcomeOverlay />

              <BeschlussGeneratorAppPromotionOverlay />
            </>
          )}
        </NoSsr>

        <RVORechtsformWarning />

        <RVORoleWarning />

        <RVOContainer noPaddingTop={true} className={classes.container}>
          <RVOModal
            className={classes.pickerModal}
            isOpen={isEmployeeParticipationShowing}
            onClose={closeEmployeeParticipationModal}
            disableClose={true}
            size={RVOModalSize.Large}
            blur={4}
          >
            <Box padding={theme.spacing(4)}>
              <Alert
                severity="warning"
                style={{ marginBottom: theme.spacing(3) }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  <HtmlFromLangFile
                    contentContainingHtml={t(
                      "mitarbeiterbeteiligung.templateWarning"
                    )}
                  />
                </Typography>
              </Alert>

              <RVOCtaEmployeeParticipation learnMoreOnly={true} />
            </Box>
          </RVOModal>

          {isCscTemplateShowing && (
            <Box padding={theme.spacing(1)}>
              <Alert
                severity="warning"
                style={{ marginBottom: theme.spacing(3) }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  style={{ fontWeight: 800 }}
                >
                  <HtmlFromLangFile
                    contentContainingHtml={t(
                      "anbauvereinigung.templateWarning"
                    )}
                  />
                </Typography>
              </Alert>
            </Box>
          )}

          <Box
            style={{
              zIndex: isCscTemplateShowing ? 1000 : -1001,
              filter: isCscTemplateShowing ? "blur(4px)" : undefined,
              opacity: isCscTemplateShowing ? 1 : undefined,
            }}
          >
            <Grid
              style={{
                zIndex: isCscTemplateShowing ? -1000 : undefined,
                position: isCscTemplateShowing ? "relative" : "static",
              }}
              container={true}
              spacing={2}
              className={classes.previewAndInputWrapper}
            >
              <Grid
                item={true}
                xs={12}
                md={6}
                lg={7}
                className={classes.beschlussPaperWrapper}
              >
                <Paper elevation={20} className={classes.beschlussPaper}>
                  <BeschlussPreview
                    vorlage={vorlage}
                    beschlussElement={beschlussElement}
                  />
                </Paper>
              </Grid>

              <Grid item={true} xs={12} md={6} lg={5}>
                <Box className={classes.stickyWrapper}>
                  <Box className={classes.fixedHeightScrollBox}>
                    <Box className={classes.informationStepperWrapper}>
                      <NoSsr>
                        <BeschlussInput />
                      </NoSsr>
                    </Box>
                  </Box>

                  <Box className={classes.generatorButtons}>
                    {createBeschlussButton}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </RVOContainer>

        <BeschlussGeneratorMobilePreviewButton
          onClick={toggleIsMobilePreviewShowing}
          previewIsShowing={isMobilePreviewShowing}
        />

        <NoSsr>
          {vorlageIsLoaded && (
            <BeschlussPreviewOnMobile isShowing={isMobilePreviewShowing} />
          )}
        </NoSsr>

        <NoSsr>
          <BeschlussFinishedOverlay
            state={ephemeralUserInputState.generatorState}
            onCloseDrawer={hideDrawer}
            isOpen={isDrawerOpen}
            onSavePdf={savePdf}
          />
        </NoSsr>
      </EphemeralUserInputContext.Provider>
    </>
  );
};

export default BeschlussGenerator;
