import { PopperProps } from "@material-ui/core";
import { useBooleanState, useSmoothWindowScroll } from "@nvon/react-toolbox";
import { useCallback, useEffect, useState } from "react";
import { useEphemeralUserInputState } from "../ephemeralUserInput/useEphemeralUserInputState";
import {
  BeschlussInputProps,
  BeschlussInputViewModel,
} from "./BeschlussInputInterfaces";
import {
  getFirstIncompleteStepIndex,
  stepperCollapsedIndex,
} from "./stepHelpers/stepHelpers";
import { useAllBeschlussInputSteps } from "./stepHelpers/useAllBeschlussInputSteps";

const getActiveStepElement = () => {
  const element = document.querySelector(".MuiStep-root.isActive");
  if (!element) {
    throw Error(`no step found`);
  }

  return element;
};

export const useBeschlussInputViewModel = ({
  overrideSteps,
  startCollapsed,
  onStepChange,
}: BeschlussInputProps): BeschlussInputViewModel => {
  const allSteps = useAllBeschlussInputSteps();
  const scrollTo = useSmoothWindowScroll();

  const inputSteps = overrideSteps || allSteps;

  const [activeStep, goToStepNumber] = useState<number>(() =>
    startCollapsed
      ? stepperCollapsedIndex
      : getFirstIncompleteStepIndex(inputSteps)
  );

  useEffect(() => {
    onStepChange?.(activeStep);
  }, [activeStep, onStepChange]);

  useEffect(() => {
    if (!startCollapsed && activeStep === stepperCollapsedIndex) {
      goToStepNumber(getFirstIncompleteStepIndex(inputSteps));
    }
  }, [activeStep, inputSteps, startCollapsed]);

  const { externallyFocusedVariables, externallyFocusedStep } =
    useEphemeralUserInputState();

  const goToPreviousStep = useCallback(() => {
    goToStepNumber((old) => old - 1);
  }, []);

  const goToNextStep = useCallback(() => {
    goToStepNumber((old) => old + 1);
  }, []);

  const [popperAnchor, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);

  const {
    state: isPopperShowing,
    on: setIsPopperShowingTrue,
    off: setIsPopperShowingFalse,
  } = useBooleanState(false);

  const showPopper = useCallback(() => {
    const element = getActiveStepElement();

    const getBoundingClientRect = () => element.getBoundingClientRect();

    setAnchorEl({
      clientWidth: getBoundingClientRect().width,
      clientHeight: getBoundingClientRect().height,
      getBoundingClientRect,
    });

    setIsPopperShowingTrue();

    setTimeout(setIsPopperShowingFalse, 3000);
  }, [setIsPopperShowingFalse, setIsPopperShowingTrue]);

  /**
   * when a variable gets focused, jump to the antrag input step.
   */
  useEffect(() => {
    const hasValue = Object.values(externallyFocusedVariables || {}).reduce(
      (sum, current) => sum + current,
      0
    );
    if (hasValue) {
      goToStepNumber(2);
    }
  }, [externallyFocusedVariables]);

  useEffect(() => {
    if (externallyFocusedStep) {
      goToStepNumber(externallyFocusedStep.step);

      // We use the timeout to account for the stepper animation.
      if (externallyFocusedStep.step === 1) {
        setTimeout(() => {
          showPopper();
          scrollTo(getActiveStepElement());
        }, 500);
      }
    }
  }, [externallyFocusedStep, scrollTo, showPopper]);

  return {
    inputSteps,
    popperAnchor,
    isPopperShowing,
    activeStep,
    goToNextStep,
    goToPreviousStep,
    goToStepNumber,
  };
};
