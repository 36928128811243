import { createStyles, makeStyles } from "@material-ui/core";

export const useBeschlussFinishedOverlayStyles = makeStyles((theme) => {
  return createStyles({
    wrapper: {
      "& .MuiTypography-root:not(.MuiListItemText-root span)": {
        marginBottom: theme.spacing(1),
      },
    },
    chevron: {
      transition: "transform 0.35s ease-in-out",
    },
    iconFlipped: {
      transform: "rotate(90deg)",
    },
    loadingProgressContent: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.secondary.light,
    },
    actionButtons: {
      marginTop: theme.spacing(1),
    },
    downloadButton: {
      marginTop: theme.spacing(1),
    },
    confettiWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      height: "100vh !important",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      overflow: "hidden",
      pointerEvents: "none",
    },
    hideOnMobile: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  });
});
