import { createStyles, makeStyles } from "@material-ui/core";
import { warningBackground } from "../../../helpers/styling/warningBackground";
import {
  beschlussPreviewPadding,
  beschlussPreviewPaddingBetween,
} from "../../04-templates/BeschlussGenerator/beschlussGeneratorConstants";

export const useRVOCoatingWrapperStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(
        beschlussPreviewPadding,
        beschlussPreviewPadding,
        beschlussPreviewPadding,
        beschlussPreviewPadding
      ),
      marginBottom: theme.spacing(beschlussPreviewPaddingBetween),
      backgroundColor: theme.palette.grey[50],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: warningBackground(theme),
    },
    title: {
      marginBottom: theme.spacing(0),
      textAlign: "center",
      maxWidth: "100%",
    },
    subtitle: {
      marginTop: theme.spacing(2),
    },
  })
);
