import { useBooleanState } from "@nvon/react-toolbox";
import { useCallback, useState } from "react";
import {
  BeschlussInputStep,
  StepStatus,
} from "../BeschlussInput/BeschlussInputInterfaces";
import { allStepsAreCompleted as areAllStepsAreCompleted } from "../BeschlussInput/stepHelpers/stepHelpers";
import { useFirstTwoBeschlussInputSteps } from "../BeschlussInput/stepHelpers/useFirstTwoBeschlussInputSteps";
import { useEphemeralUserInputState } from "../ephemeralUserInput/useEphemeralUserInputState";
import { BeschlussGeneratorWelcomeOverlayViewModel } from "./BeschlussGeneratorWelcomeOverlayInterfaces";

export const useBeschlussGeneratorWelcomeOverlayViewModel =
  (): BeschlussGeneratorWelcomeOverlayViewModel => {
    const { externallyFocusStep } = useEphemeralUserInputState();

    const steps = useFirstTwoBeschlussInputSteps();

    const [currentStep, setCurrentStep] = useState<
      BeschlussInputStep | undefined
    >(steps[0]);

    const onStepChange = useCallback(
      (stepIndex: number) => setCurrentStep(steps[stepIndex]),
      [steps]
    );

    const allStepsCompleted = areAllStepsAreCompleted(steps);

    const showStepOneWarning =
      currentStep === steps[1] &&
      steps[0] &&
      steps[0].status === StepStatus.Open;

    // Only display at all if there are unfinished steps.
    const showInitially = !allStepsCompleted;

    const { state: isOverlayOpen, off: closeOverlay } =
      useBooleanState(showInitially);

    const proceedToBeschluss = useCallback(() => {
      closeOverlay();
      setTimeout(() => externallyFocusStep(2), 50);
    }, [externallyFocusStep, closeOverlay]);

    return {
      isOverlayOpen,
      closeOverlay,
      showStepOneWarning,
      onStepChange,
      steps,
      allStepsCompleted,
      proceedToBeschluss,
    };
  };
