import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  useCompanySitz,
  useSetSitz,
} from "../../../../../state/companyInfo/companyInfoSlice";

export const BeschlussInputWhoSitz = (): JSX.Element => {
  const { t } = useTranslation();
  const setSitz = useSetSitz();

  const currentSitz = useCompanySitz();

  const sitzInput = (
    <TextField
      placeholder={t("companyInfo.sitz")}
      variant="outlined"
      label={t("companyInfo.sitz")}
      fullWidth={true}
      onChange={(e) => {
        const newSitz = e.target.value;

        setSitz(newSitz);
      }}
      value={currentSitz}
    />
  );

  return sitzInput;
};
