import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  useCompanyFirma,
  useSetFirma,
} from "../../../../../state/companyInfo/companyInfoSlice";

export const BeschlussInputWhoFirma = (): JSX.Element => {
  const { t } = useTranslation();
  const setFirma = useSetFirma();

  const currentFirma = useCompanyFirma();

  const firmaInput = (
    <TextField
      placeholder={t("companyInfo.firma")}
      variant="outlined"
      label={t("companyInfo.firma")}
      fullWidth={true}
      onChange={(e) => {
        const newFirma = e.target.value;

        setFirma(newFirma);
      }}
      value={currentFirma}
      autoFocus={true}
    />
  );

  return firmaInput;
};
