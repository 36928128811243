import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

export const rvoScrollbarStyles = (theme: Theme): CSSProperties => ({
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: "6px",
  },
  scrollBarWidth: "thin",
  scrollBarColor: theme.palette.primary.dark,
});
