import { Card, CardContent, Modal, NoSsr, Paper } from "@material-ui/core";
import { useCurrentVorlageWithUserInput } from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import BeschlussPreview from "../BeschlussPreview/BeschlussPreview";
import { BeschlussPreviewOnMobileProps } from "./BeschlussPreviewOnMobileInterfaces";
import { useBeschlussPreviewOnMobileStyles } from "./BeschlussPreviewOnMobileStyles";

const BeschlussPreviewOnMobile = (
  props: BeschlussPreviewOnMobileProps
): JSX.Element => {
  const classes = useBeschlussPreviewOnMobileStyles();
  const { vorlage } = useCurrentVorlageWithUserInput();

  return (
    <Modal
      open={props.isShowing}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ zIndex: 1100 }}
    >
      <Card className={classes.previewCard}>
        <CardContent className={classes.previewCardContent}>
          <Paper elevation={20} className={classes.beschlussPaper}>
            <NoSsr>
              <BeschlussPreview vorlage={vorlage} />
            </NoSsr>
          </Paper>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default BeschlussPreviewOnMobile;
