import { Typography } from "@material-ui/core";
import { useBooleanState } from "@nvon/react-toolbox";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isCompanyTypeLandingpage } from "../../../../data/juristicalData/isCompanyTypeLandingpage";
import { rechtsformNames } from "../../../../data/juristicalData/rechtsformNames";
import { useCurrentVorlage } from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import { useCompanyRechtsform } from "../../../../state/companyInfo/companyInfoSlice";
import { RVOWarning } from "../../../02-molecules/RVOWarning/RVOWarning";

/**
 * Warning the user when the currently selected Rechtsform is not matching
 *  the vorlage.geeignetFuerRechtsformen
 *
 * Has some duplication with the RoleWarning. Could be refactored at some point.
 */
const RVORechtsformWarning = (): JSX.Element | null => {
  const { t } = useTranslation();

  const selectedRechtsform = useCompanyRechtsform();

  /**
   * Track if the user has disabled (e.g. closed) the warning.
   */
  const {
    state: warningDisabled,
    on: setWarningDisabledTrue,
    off: setWarningDisabledFalse,
  } = useBooleanState(false);

  /**
   * Reset when the user selects something new.
   */
  useEffect(setWarningDisabledFalse, [
    setWarningDisabledFalse,
    selectedRechtsform,
  ]);

  const vorlage = useCurrentVorlage();

  const wrongOptionSelected =
    selectedRechtsform &&
    vorlage &&
    !vorlage.geeignetFuerRechtsformen.includes(selectedRechtsform);

  const showWarning = !!wrongOptionSelected && !warningDisabled;

  const rechtsformLabel =
    selectedRechtsform && isCompanyTypeLandingpage(selectedRechtsform)
      ? rechtsformNames[selectedRechtsform]
      : "";

  return (
    <RVOWarning
      onClose={setWarningDisabledTrue}
      isOpen={showWarning}
      title={t("companyInfo.notSuiteableFor", { what: rechtsformLabel })}
    >
      <Typography variant="body1">
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t("companyInfo.currentRechtsformNotRecommended", {
              beschlussName: vorlage?.kurzBezeichnung,
              rechtsformName: rechtsformLabel,
            }),
          }}
        />
      </Typography>

      <br />

      <Typography variant="body1">
        {t("companyInfo.needAdjustmentGetAdvise")}
      </Typography>
    </RVOWarning>
  );
};

export default RVORechtsformWarning;
