import { createStyles, makeStyles } from "@material-ui/core";

export const useBeschlussGeneratorWelcomeOverlayStyles = makeStyles((theme) =>
  createStyles({
    modal: {},
    buttonBox: {
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      textAlign: "right",
    },
    hints: {
      marginBottom: theme.spacing(2),
      "& .MuiTypography-root + .MuiTypography-root": {
        marginTop: theme.spacing(1),
      },
    },
    input: {
      marginTop: theme.spacing(2),
    },
    dataIsSafe: {
      marginBottom: theme.spacing(2),
      "& svg": {
        transform: "translateX(-5px)",
      },
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    letsGoButton: {
      marginTop: theme.spacing(2),
      display: "block",
    },
    alert: {
      "& em": {
        textDecoration: "underline",
        fontStyle: "normal",
        textUnderlineOffset: "1.5px",
        "&:hover": {
          textShadow: `0 0 1px ${theme.palette.success.dark}`,
        },
      },
      marginTop: theme.spacing(2),
    },
  })
);
