import { Box } from "@material-ui/core";
import { BeschlussInputWhoFirma } from "./BeschlussInputWhoFirma";
import { BeschlussInputWhoRechtsform } from "./BeschlussInputWhoRechtsform";
import { BeschlussInputWhoSitz } from "./BeschlussInputWhoSitz";
import { useBeschlussInputWhoStyles } from "./BeschlussInputWhoStyles";
import { BeschlussInputWhoWhoCanDecide } from "./BeschlussInputWhoWhoCanDecide";

/**
 * Define who will be doing the Beschluss.
 * This encompasses multiple fileds, all of which are kept in the ComanyInfoState in redux.
 */
const BeschlussInputWho = (): JSX.Element => {
  const classes = useBeschlussInputWhoStyles();
  return (
    <Box className={classes.wrapper}>
      <BeschlussInputWhoFirma />

      <BeschlussInputWhoSitz />

      <BeschlussInputWhoRechtsform />

      <BeschlussInputWhoWhoCanDecide />
    </Box>
  );
};

export default BeschlussInputWho;
