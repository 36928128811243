import { Box } from "@material-ui/core";
import { ComponentWithClassNameProps } from "@nvon/react-toolbox";
import { useMemo } from "react";
import { mapBeschlussVorlageContent } from "../../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { LegacyTemplateLandingpage } from "../../../../helpers/createVorlageLandingpage";

export interface BeschlussContentRenderedProps
  extends ComponentWithClassNameProps {
  vorlage: LegacyTemplateLandingpage;
  shouldHyphenate: boolean;
}

export const BeschlussContentRendered = ({
  className,
  vorlage,
  shouldHyphenate,
}: BeschlussContentRenderedProps): JSX.Element => {
  const renderedAntrag = useMemo(() => {
    return replaceHtmlWithFunctionComponent(
      vorlage.antrag,
      mapBeschlussVorlageContent(vorlage, shouldHyphenate)
    );
  }, [shouldHyphenate, vorlage]);

  return <Box className={className}>{renderedAntrag}</Box>;
};
