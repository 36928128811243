import { Box, Typography } from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  NWONLottieWrapper,
  useBooleanState,
  useTrigger,
} from "@nvon/react-toolbox";
import { Button, Link } from "gatsby-material-ui-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import confetti from "../../../../resources/lottie-jsons/confetti.json";
import { useCurrentVorlage } from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import {
  RVOTrackingEvent,
  trackEvent,
} from "../../../00-globals/TruendoGoogleTagManager/trackEvent";
import { BeschlussFinishedProgressBar } from "../../../01-atoms/BeschlussFinishedProgressBar/BeschlussFinishedProgressBar";
import { RVOAppPromotionContent } from "../../../02-molecules/RVOAppPromotionContent/RVOAppPromotionContent";
import RVOModal from "../../../02-molecules/RVOModal/RVOModal";
import {
  RVOModalLevel,
  RVOModalSize,
} from "../../../02-molecules/RVOModal/RVOModalInterfaces";
import BeschlussHandelsregisterpflichtigStepper from "../BeschlussHandelsregisterpflichtigStepper/BeschlussHandelsregisterpflichtigStepper";
import { BeschlussGeneratorState } from "../ephemeralUserInput/EphemeralUserInputContext";
import { BeschlussFinishedDrawerProps } from "./BeschlussFinishedOverlayInterfaces";
import { useBeschlussFinishedOverlayStyles } from "./BeschlussFinishedOverlayStyles";

const BeschlussFinishedOverlay = (
  props: BeschlussFinishedDrawerProps
): JSX.Element => {
  const { isOpen, onCloseDrawer, onSavePdf, state } = props;
  const { t } = useTranslation();
  const classes = useBeschlussFinishedOverlayStyles();
  useBooleanState(false);
  const vorlage = useCurrentVorlage();

  const { trigger: triggerPlaying, activateTrigger: playAnimation } =
    useTrigger();

  useEffect(() => {
    playAnimation();
  }, [playAnimation]);

  const isLoading = state === BeschlussGeneratorState.Generating;

  const loading = (
    <Box>
      <Box className={classes.loadingProgressContent}>
        <Typography variant="h4">
          {t("beschlussFinishedOverlay.promoTitle")}
        </Typography>
      </Box>
    </Box>
  );

  const finished = !vorlage?.isHandelsregisterpflichtig ? (
    <>
      <RVOAppPromotionContent />

      <Alert severity="success">
        <AlertTitle>{t("vorlagen.thankYouText")}</AlertTitle>

        <Box>
          <Typography variant="body1">
            <Link
              target={"_blank"}
              href="/beschluss-basics/nach-der-beschlusserstellung-was-jetzt"
            >
              {t("vorlagen.nowWhatText")}
            </Link>
          </Typography>
        </Box>
      </Alert>

      <Button
        variant="contained"
        onClick={() => {
          onSavePdf();
          trackEvent(RVOTrackingEvent.PdfDownloadClicked);
        }}
        endIcon={<DownloadIcon />}
        fullWidth={true}
        className={classes.downloadButton}
      >
        {t("vorlagen.downloadBeschlussPdf")}
      </Button>

      <Box className={classes.confettiWrapper}>
        <NWONLottieWrapper
          lottieFileJson={confetti}
          triggerPlaying={triggerPlaying}
        />
      </Box>
    </>
  ) : (
    <BeschlussHandelsregisterpflichtigStepper
      onSavePdf={onSavePdf}
      vorlage={vorlage}
    />
  );

  return (
    <RVOModal
      level={isLoading ? RVOModalLevel.Info : RVOModalLevel.Success}
      isOpen={isOpen}
      className={classes.wrapper}
      disableClose={isLoading}
      size={RVOModalSize.Regular}
      onClose={onCloseDrawer}
    >
      {isLoading ? loading : finished}

      {isLoading ? <BeschlussFinishedProgressBar /> : null}
    </RVOModal>
  );
};

export default BeschlussFinishedOverlay;
