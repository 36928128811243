import { Box, NoSsr } from "@material-ui/core";
import { useSelectedVotingMode } from "../../../../../state/votingMode/votingModeSlice";
import { StepStatus } from "../../BeschlussInput/BeschlussInputInterfaces";
import { useBeschlussInputVotingModeStep } from "../../BeschlussInput/stepHelpers/useBeschlussInputVotingModeStep";
import { useBeschlussInputWhoStep } from "../../BeschlussInput/stepHelpers/useBeschlussInputWhoStep";
import BeschlussCoatingHeaderPlaceholder from "./BeschlussCoatingHeaderPlaceholder";
import { useBeschlussCoatingHeaderStyles } from "./BeschlussCoatingHeaderStyles";

const BeschlussCoatingHeader = (): JSX.Element => {
  const votingMode = useSelectedVotingMode();
  const { status: whoStatus } = useBeschlussInputWhoStep();
  const { status: modeStatus } = useBeschlussInputVotingModeStep();
  const classes = useBeschlussCoatingHeaderStyles();

  const ready =
    whoStatus === StepStatus.Complete && modeStatus === StepStatus.Complete;

  return (
    <NoSsr>
      {ready && votingMode ? (
        <Box className={classes.votingModeWrapper}>
          <votingMode.CoatingHeader />
        </Box>
      ) : (
        <BeschlussCoatingHeaderPlaceholder />
      )}
    </NoSsr>
  );
};

export default BeschlussCoatingHeader;
