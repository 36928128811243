import { Box } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { TemplateVariable } from "../../../../../common-interfaces/VorlageInterface";
import { freitext } from "../../../../../data/vorlagen/vorlagenShared/allTemplateVariables";
import { templateVariableKey } from "../../../../../helpers/general/templates/templateVariableKey";
import {
  useCurrentVorlageWithUserInput,
  useSetShowVorlagenPicker,
  useSetUserInputOnCurrentVorlage,
} from "../../../../../state/beschlussVorlage/beschlussVorlageSlice";
import { useSelectedVotingMode } from "../../../../../state/votingMode/votingModeSlice";
import { SanitizeHTML } from "../../../../01-atoms/SanitizeHTML/SanitzeHTML";
import DropdownText from "../../../../02-molecules/DropdownText/DropdownText";
import { emptyUserInputValue } from "../../beschlussGeneratorConstants";
import { TemplateUserInput } from "../../BeschlussGeneratorInterfaces";
import { EphemeralUserInputContext } from "../../ephemeralUserInput/EphemeralUserInputContext";
import TemplateVariableInput from "../../TemplateVariableInput/TemplateVariableInput";
import { useBeschlussInputAntragStyles } from "./BeschlussInputAntragStyles";

/**
 * The input fields for the Beschlussantrag.
 */
const BeschlussInputAntrag = (): JSX.Element => {
  const classes = useBeschlussInputAntragStyles();
  const votingMode = useSelectedVotingMode();
  const { t } = useTranslation();

  const setIsPickerShowing = useSetShowVorlagenPicker();

  const { resetExternallyFocusedVariables } = useContext(
    EphemeralUserInputContext
  );

  // Reset externally focused on un-mount
  useEffect(
    () => resetExternallyFocusedVariables,
    [resetExternallyFocusedVariables]
  );

  const { vorlage, userInput } = useCurrentVorlageWithUserInput();

  const setUserInput = useSetUserInputOnCurrentVorlage();

  const getChangeHandlerForKey: (
    key: keyof TemplateUserInput
  ) => ChangeEventHandler<HTMLInputElement> = useCallback(
    (key) => {
      return (event) => {
        const { value } = event.target;
        setUserInput(key, value);
      };
    },
    [setUserInput]
  );

  const renderVariable = useCallback(
    (variable: TemplateVariable, index: number): JSX.Element => {
      const key = templateVariableKey(variable);
      const onChange = getChangeHandlerForKey(key);
      const value = userInput[key] || emptyUserInputValue;

      return (
        <>
          <TemplateVariableInput
            key={key}
            {...{
              variable,
              onChange,
              value,
              index,
              shouldAutoFocus: index === 0,
            }}
          />

          {variable.name === freitext.name ? (
            <Box className={classes.wrapperEditVorlage}>
              <DropdownText
                iconOverride={<EditOutlinedIcon />}
                onClick={() => setIsPickerShowing(true)}
              >
                <SanitizeHTML
                  tag="span"
                  html={t("vorlagen.changeVorlageAlt")}
                />
              </DropdownText>
            </Box>
          ) : (
            ""
          )}
        </>
      );
    },
    [
      getChangeHandlerForKey,
      userInput,
      t,
      setIsPickerShowing,
      classes.wrapperEditVorlage,
    ]
  );

  /**
   * Consisting of regular input fields from the Vorlage,
   *  and the additional fields from the voting mode (which are rendered first).
   */
  const allInputFields = useMemo(() => {
    const additionalInputs = votingMode?.additionalInputs || [];
    const allFields = [...additionalInputs, ...vorlage.templateVariables];

    return allFields.map(renderVariable);
  }, [renderVariable, vorlage.templateVariables, votingMode?.additionalInputs]);

  return <Box className={classes.wrapper}>{allInputFields}</Box>;
};

export default BeschlussInputAntrag;
