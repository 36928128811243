import { Fab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { BeschlussGeneratorMobilePreviewButtonProps } from "./BeschlussGeneratorMobilePreviewButtonInterfaces";
import { useBeschlussGeneratorMobilePreviewButtonStyles } from "./BeschlussGeneratorPreviewButtonStyles";

/**
 * The floating action button for showing the beschluss preview. Displayed mobile only.
 */
const BeschlussGeneratorMobilePreviewButton = ({
  onClick,
  previewIsShowing,
}: BeschlussGeneratorMobilePreviewButtonProps): JSX.Element => {
  const classes = useBeschlussGeneratorMobilePreviewButtonStyles();

  return (
    <Fab color="primary" className={classes.previewIcon} onClick={onClick}>
      {previewIsShowing ? <CloseIcon /> : <VisibilityIcon />}
    </Fab>
  );
};

export default BeschlussGeneratorMobilePreviewButton;
