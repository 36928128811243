import { useCallback } from "react";
import { RolleInGesellschaft } from "../../../../../common-interfaces/VorlageInterface";
import { AnyRole } from "../../../../../interfaces/variousInterfaces";
import { useCurrentVorlageOrThrow } from "../../../../../state/beschlussVorlage/beschlussVorlageSlice";
import { EmptyUserInput } from "../../beschlussGeneratorConstants";

/**
 * Returns a function that will check if a given company role
 *  is invalid for the currently selected vorlage.
 *
 * Note that the empty state does not count as invalid.
 */
export const useRoleIsInvalid = (): ((
  role: AnyRole | EmptyUserInput
) => boolean) => {
  const vorlage = useCurrentVorlageOrThrow();

  return useCallback(
    (role) => {
      return (
        !!role &&
        !vorlage.vorlage_rolleTyp.includes(role as RolleInGesellschaft)
      );
    },
    [vorlage]
  );
};
