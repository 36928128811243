import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import { headerHeight } from "../../../helpers/clientSide/constants";
import { rvoScrollbarStyles } from "../../../helpers/styling/rvoScrollbarStyles";
import {
  beschlussGeneratorDesktopBreakpoint,
  beschlussGeneratorMobileBreakpoint,
} from "./BeschlussGeneratorViewModel";

export const beschlussPaperStyles = (theme: Theme): CSSProperties => ({
  height: "100%",
  position: "relative",
  userSelect: "none",
  [theme.breakpoints.down("md")]: {
    minHeight: "700px",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "auto",
    overflow: "scroll",
    maxHeight: "100vh",
    boxShadow: "none",
  },
});

export const useBeschlussGeneratorStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      body: {
        overflowX: "initial !important",
      },
    },
    previewAndInputWrapper: {},
    container: {
      padding: theme.spacing(2, 0),
    },
    informationStepperWrapper: {
      padding: theme.spacing(1),
      [theme.breakpoints.down(beschlussGeneratorMobileBreakpoint)]: {
        paddingInline: "1rem",
      },
    },
    stickyWrapper: {
      position: "sticky",
      top: headerHeight,
    },
    pickerModal: {
      "& > .MuiPaper-root": {
        padding: 0,
      },
    },
    fixedHeightScrollBox: {
      [theme.breakpoints.down(beschlussGeneratorMobileBreakpoint)]: {
        width: "100%",
        maxWidth: "100%",
      },
      [theme.breakpoints.up(beschlussGeneratorDesktopBreakpoint)]: {
        // the "200px" reflects the approximate height of the "create documents" button
        maxHeight: `calc(100vh - ${headerHeight}px - 260px)`,
        overflowY: "auto",
        overflowX: "visible",
        ...rvoScrollbarStyles(theme),
      },
    },
    beschlussPaperWrapper: {
      [theme.breakpoints.down("xl")]: {
        minHeight: "1000px",
      },
      [theme.breakpoints.down("md")]: {
        minHeight: "700px",
      },
      [theme.breakpoints.down(beschlussGeneratorMobileBreakpoint)]: {
        display: "none",
      },
    },
    beschlussPaper: {
      ...beschlussPaperStyles(theme),
    },
    vorlagenMetaWrapper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom: theme.spacing(4),
    },
    generatorButtons: {
      padding: theme.spacing(1, 1, 0, 3),
      "& button": {
        marginTop: theme.spacing(1),
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
    },
  })
);
