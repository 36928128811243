import { objectMap } from "@nvon/baseline";
import { useCallback, useMemo, useState } from "react";
import { TemplateVariableKey } from "../../../../interfaces/variousInterfaces";
import {
  BeschlussGeneratorState,
  EphemeralUserInputState,
  ExternallyFocusedStep,
} from "./EphemeralUserInputContext";

/**
 * Provide EphemeralUserInputState, to be used by a Context.Provider
 *
 * (Check the EphemeralUserInputState interface for an explanation of what this does)
 */
export const useEphemeralUserInputStateProvider =
  (): EphemeralUserInputState => {
    const [generatorState, setGeneratorState] =
      useState<BeschlussGeneratorState>(BeschlussGeneratorState.UserInput);

    const [currentlyFocusedVariable, setCurrentlyFocusedVariable] =
      useState<TemplateVariableKey | null>(null);

    const unFocusVariable = useCallback(
      () => setCurrentlyFocusedVariable(null),
      []
    );

    const [externallyFocusedStep, setExternallyFocusedStep] =
      useState<ExternallyFocusedStep>(null);

    const externallyFocusStep = useCallback((stepNumber: number) => {
      setExternallyFocusedStep(() => ({
        step: stepNumber,
      }));
    }, []);

    /**
     *
     * Represents an input element being focused externally (e.g. without clicking on the input itself).
     * This currently triggers a little zoom on the input, and selection of the appropriate step in the stepper.
     *
     * While this is implemented as a counter (a single number for each variable),
     *  the number is irrelevant and merely serves as a trigger:
     *
     * Each time a variable is focused, the counter is increased, and somebody can react to this.
     */
    const [externallyFocusedVariables, setExternallyFocused] = useState<
      Record<TemplateVariableKey, number>
    >({});

    const externallyFocusVariable: EphemeralUserInputState["externallyFocusVariable"] =
      useCallback((key) => {
        setExternallyFocused((old) => ({
          ...old,
          [key]: (old[key] || 0) + 1,
        }));
      }, []);

    const resetExternallyFocusedVariables: EphemeralUserInputState["resetExternallyFocusedVariables"] =
      useCallback(() => {
        setExternallyFocused((old) => objectMap(old, () => 0));
      }, []);

    const ephemeralUserInputState: EphemeralUserInputState = useMemo(() => {
      return {
        generatorState,
        setGeneratorState,
        externallyFocusedStep,
        currentlyFocusedVariable,
        externallyFocusStep,
        externallyFocusedVariables,
        externallyFocusVariable,
        resetExternallyFocusedVariables,
        unFocusVariable,
        focusVariable: setCurrentlyFocusedVariable,
      };
    }, [
      currentlyFocusedVariable,
      externallyFocusStep,
      externallyFocusVariable,
      externallyFocusedStep,
      externallyFocusedVariables,
      generatorState,
      resetExternallyFocusedVariables,
      unFocusVariable,
    ]);

    return ephemeralUserInputState;
  };
