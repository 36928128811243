import { Box, Typography } from "@material-ui/core";
import { RVOCoatingWrapperProps } from "./RVOCoatingWrapperInterfaces";
import { useRVOCoatingWrapperStyles } from "./RVOCoatingWrapperStyles";

const RVOCoatingWrapper = (props: RVOCoatingWrapperProps): JSX.Element => {
  const classes = useRVOCoatingWrapperStyles();

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title} variant="h2">
        {props.title}

        {props.whoPart}

        {props.firmaPart}
      </Typography>
    </Box>
  );
};

export default RVOCoatingWrapper;
