import { truncate } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LegacyTemplateLandingpage } from "../../../helpers/createVorlageLandingpage";

export const useVorlagenSeo = (
  vorlage: LegacyTemplateLandingpage
): {
  title: string;
  metaDescription: string;
} => {
  const { t } = useTranslation();

  return useMemo(() => {
    const metaDescriptionFallback = `Vorlage für Beschluss: ${vorlage.kurzBezeichnung}`;

    const metaDescriptionFullLength =
      vorlage.metaDescription || metaDescriptionFallback;

    const pageTitle =
      vorlage.kurzBezeichnung + t("seo.beschlussGenerator.title");

    const metaDescription = truncate(metaDescriptionFullLength, {
      length: 155,
    });

    return {
      title: pageTitle,
      metaDescription,
    };
  }, [t, vorlage.kurzBezeichnung, vorlage.metaDescription]);
};
