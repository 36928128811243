import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Link } from "gatsby-material-ui-components";
import { useTranslation } from "react-i18next";
import { mapGenericContent } from "../../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { LegacyTemplateLandingpage } from "../../../../helpers/createVorlageLandingpage";
import theme from "../../../../layouts/theme";
import {
  RVOTrackingEvent,
  trackEvent,
} from "../../../00-globals/TruendoGoogleTagManager/trackEvent";
import BeschlussNotarizeButton from "../BeschlussNotarizeButton/BeschlussNotarizeButton";
import { useBeschlussHandelsregisterpflichtigStepperStyles } from "./BeschlussHandelsregisterpflichtigStepperStyles";

export interface BeschlussHandelsregisterpflichtigStepperProps {
  vorlage: LegacyTemplateLandingpage;
  onSavePdf: VoidFunction;
}

const notarityLink = "notarity.com";

const BeschlussHandelsregisterpflichtigStepper = ({
  vorlage,
  onSavePdf,
}: BeschlussHandelsregisterpflichtigStepperProps): JSX.Element => {
  const classes = useBeschlussHandelsregisterpflichtigStepperStyles();
  const { t } = useTranslation();

  return (
    <>
      <Alert severity="info">
        <Typography variant="caption">
          {replaceHtmlWithFunctionComponent(
            vorlage.noteHandelsregisteranmeldung,
            mapGenericContent
          )}
        </Typography>
      </Alert>

      <Stepper
        inlist={true}
        orientation="vertical"
        style={{
          padding: 0,
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}
      >
        <Step active={true}>
          <StepLabel className={classes.stepLabel}>
            <Button
              variant="contained"
              onClick={() => {
                onSavePdf();
                trackEvent(RVOTrackingEvent.PdfDownloadClicked);
              }}
              endIcon={<DownloadIcon />}
              fullWidth={true}
            >
              {t("vorlagen.downloadBeschlussPdf")}
            </Button>
          </StepLabel>
        </Step>

        <Step active={true}>
          <StepLabel className={classes.stepLabel}>
            <BeschlussNotarizeButton />
          </StepLabel>
        </Step>
      </Stepper>

      <Typography variant="body2">
        {t("beschlussFinishedOverlay.para1")}

        <strong>
          <Link target="_blank" to="https://notarity.com">
            {notarityLink}
          </Link>
        </strong>

        {t("beschlussFinishedOverlay.para2")}
      </Typography>

      <Typography variant="body2">
        {t("beschlussFinishedOverlay.para3")}
      </Typography>
    </>
  );
};

export default BeschlussHandelsregisterpflichtigStepper;
