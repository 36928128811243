import { useCallback } from "react";
import { CompanyTypeName } from "../../../../../data/vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { useCurrentVorlageOrThrow } from "../../../../../state/beschlussVorlage/beschlussVorlageSlice";
import { EmptyUserInput } from "../../beschlussGeneratorConstants";

/**
 * Returns a function that will check if a given rechtsform
 *  is invalid for the currently selected vorlage.
 *
 * Note that the empty state does not count as invalid.
 */
export const useRechtsformIsInvalid = (): ((
  rechtsform: CompanyTypeName | EmptyUserInput
) => boolean) => {
  const vorlage = useCurrentVorlageOrThrow();

  return useCallback(
    (rechtsform) => {
      return (
        !!rechtsform && !vorlage.geeignetFuerRechtsformen.includes(rechtsform)
      );
    },
    [vorlage]
  );
};
