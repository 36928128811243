import { PopperProps } from "@material-ui/core";
import {
  ComponentWithClassNameProps,
  ComponentWithoutPropsOrChildren,
} from "@nvon/react-toolbox";

export enum StepStatus {
  Open = "open",
  Complete = "complete",
}

export interface BeschlussInputProps extends ComponentWithClassNameProps {
  startCollapsed?: boolean;
  overrideSteps?: BeschlussInputStep[];
  onStepChange?: (stepNumber: number) => void;
}

/**
 * A single step in the input process.
 */
export interface BeschlussInputStep {
  label: string;
  callToAction: string;
  description: string;
  incompleteWarning: string;
  component: ComponentWithoutPropsOrChildren;
  status: StepStatus;
}

export type BeschlussInputStepHook = () => BeschlussInputStep;

export interface BeschlussInputViewModel {
  activeStep: number;
  goToNextStep: VoidFunction;
  goToPreviousStep: VoidFunction;
  goToStepNumber: (stepNumber: number) => void;
  popperAnchor: PopperProps["anchorEl"];
  isPopperShowing: boolean;
  inputSteps: BeschlussInputStep[];
}
