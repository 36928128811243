import { isBrowserEnvironment } from "@nvon/baseline";
import { noop } from "lodash-es";
import {
  Html2Pdf,
  Html2PdfOptions,
  PdfReadyToEdit,
} from "../../interfaces/html2pdf";

// Since pdf creation breaks on SSR, we have to do a little dynamic import trickery.
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const html2pdf: Html2Pdf = isBrowserEnvironment()
  ? require("html2pdf.js")
  : noop;

const pdfOptions: Omit<Html2PdfOptions, "filename"> = {
  margin: [20, 0, 20, 0],
  html2canvas: { scale: 2 },
  /*
   * pagebreak: {
   *   avoid: ["p", "li li", "section", ...allHeadlineTags],
   * },
   */
};

const footerPdfHandler = (pdf: PdfReadyToEdit) => {
  const totalPages: number = pdf.internal.pages.length - 1;

  for (let i = 1; i <= totalPages; i++) {
    pdf.setPage(i);
    pdf.setFontSize(10);
    pdf.setTextColor("#a1a1a1");
    pdf.text(
      `${i}/${totalPages}`,
      pdf.internal.pageSize.getWidth() - 15,
      pdf.internal.pageSize.getHeight() - 10
    );
    pdf.text(
      "made with resolvio.com",
      15,
      pdf.internal.pageSize.getHeight() - 10
    );
  }
};

/**
 * Generate a pdf from a dom element.
 *
 * @returns a method for saving the pdf to the client (e.g. prompt a download dialog)
 */
export const generatePdfFromElement = async (
  htmlElement: HTMLElement,
  fileName: string
): Promise<() => Promise<void>> => {
  const worker = html2pdf()
    .set(pdfOptions)
    .from(htmlElement)
    .toPdf()
    .get("pdf")
    .then((pdf: PdfReadyToEdit) => {
      footerPdfHandler(pdf);
    })
    .outputPdf();

  const save = () => worker.save(fileName);

  /*
   * It actually is a thenable.
   * See comments on our Html2Pdf interface for a more detailed explanation.
   */
  // eslint-disable-next-line @typescript-eslint/await-thenable
  await worker;

  return save;
};
