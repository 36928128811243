import { createStyles, makeStyles } from "@material-ui/core";
import {
  beschlussPreviewPadding,
  beschlussPreviewPaddingBetween,
} from "../../beschlussGeneratorConstants";

export const useBeschlussCoatingFooterStyles = makeStyles((theme) =>
  createStyles({
    votingModeWrapper: {
      padding: theme.spacing(
        beschlussPreviewPaddingBetween,
        beschlussPreviewPadding,
        beschlussPreviewPadding,
        beschlussPreviewPadding
      ),

      maxWidth: "100% !important",
    },
    marginCoatingWrapper: {
      "& > div": {
        marginTop: theme.spacing(2),
        marginBottom: "0 !important",
        maxWidth: "100% !important",
      },
    },
  })
);
