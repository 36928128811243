import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GrNumber } from "../../../../../interfaces/grNumber";
import {
  CompanyInfoState,
  useCompanyInfo,
  useWhoCanDecideName,
} from "../../../../../state/companyInfo/companyInfoSlice";
import {
  BeschlussInputStepHook,
  StepStatus,
} from "../BeschlussInputInterfaces";
import BeschlussInputWho from "../BeschlussInputWho/BeschlussInputWho";

const validationKeys: (keyof CompanyInfoState)[] = [
  "firma",
  "sitz",
  "rechtsform",
  "whoCanDecide",
];

export const useBeschlussInputWhoStep: BeschlussInputStepHook = () => {
  const { t } = useTranslation();
  const companyInfo = useCompanyInfo();
  const deciderName = useWhoCanDecideName(GrNumber.Plural);

  const status = useMemo(
    () =>
      validationKeys.every((key) => !!companyInfo[key])
        ? StepStatus.Complete
        : StepStatus.Open,
    [companyInfo]
  );

  const label = useMemo(() => {
    if (status === StepStatus.Open) {
      return `${t("companyInfo.whoCanDecide")}?`;
    }

    return t("companyInfo.whoAnswer", {
      roleName: deciderName,
      firma: companyInfo.firma,
      sitz: companyInfo.sitz,
    });
  }, [companyInfo.firma, companyInfo.sitz, deciderName, status, t]);

  const strings = t("inputSteps.who", { returnObjects: true });

  const data = useMemo(
    () => ({
      description: strings.description,
      callToAction: strings.callToAction,
      incompleteWarning: strings.incompleteWarning,
      label,
      status,
      component: BeschlussInputWho,
    }),
    [
      label,
      status,
      strings.callToAction,
      strings.description,
      strings.incompleteWarning,
    ]
  );

  return data;
};
