import { useTranslation } from "react-i18next";
import { useCurrentVorlage } from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import SignaturePlaceholder from "../../../01-atoms/SignaturePlaceholder/SignaturePlaceholder";

/**
 * When a Vorlage has "zustimmungErforderlichDes" set,
 *  this means it requires an additional signature from somebody.
 *
 * This component - depending on the selected vorlage - draws this signature placeholder.
 */
const AdditionalSignature = (): JSX.Element | null => {
  const { t } = useTranslation();

  const vorlage = useCurrentVorlage();

  if (!vorlage || !vorlage.zustimmungErforderlichDes) {
    return null;
  }

  return (
    <SignaturePlaceholder>
      {t("vorlagen.signatureFrom", { who: vorlage.zustimmungErforderlichDes })}
    </SignaturePlaceholder>
  );
};

export default AdditionalSignature;
