import { Box, Button, Typography } from "@material-ui/core";
import { useBooleanState } from "@nvon/react-toolbox";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RolleInGesellschaft } from "../../../../common-interfaces/VorlageInterface";
import { useCurrentVorlage } from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import { useCompanyWhoCanDecide } from "../../../../state/companyInfo/companyInfoSlice";
import { RVOWarning } from "../../../02-molecules/RVOWarning/RVOWarning";
import { useGetRoleName } from "../BeschlussInput/BeschlussInputWho/useGetRoleName";
import { useEphemeralUserInputState } from "../ephemeralUserInput/useEphemeralUserInputState";
import { useWarningStyles } from "./WarningStyles";

/**
 * Warning the user when the currently selected Role is not matching
 *  the vorlage.vorlage_rolleTyp
 *
 * Has some duplication with the RechtsformWarning. Could be refactored at some point.
 */
const RVORoleWarning = (): JSX.Element | null => {
  const classes = useWarningStyles();
  const { t } = useTranslation();

  const selectedRole = useCompanyWhoCanDecide();
  const { externallyFocusStep } = useEphemeralUserInputState();

  const getRoleName = useGetRoleName();

  /**
   * Track if the user has disabled (e.g. closed) the warning.
   */
  const {
    state: warningDisabled,
    on: setWarningDisabledTrue,
    off: setWarningDisabledFalse,
  } = useBooleanState(false);

  /**
   * Reset when the user selects something new.
   */
  useEffect(setWarningDisabledFalse, [setWarningDisabledFalse, selectedRole]);

  const vorlage = useCurrentVorlage();

  const roleLabel = selectedRole ? getRoleName(selectedRole) : "";

  const wrongOptionSelected =
    selectedRole &&
    vorlage &&
    !vorlage.vorlage_rolleTyp.includes(selectedRole as RolleInGesellschaft);

  const showWarning = !!wrongOptionSelected && !warningDisabled;

  const adjustOrgan = useCallback(() => {
    setWarningDisabledTrue();
    externallyFocusStep(0);
  }, [externallyFocusStep, setWarningDisabledTrue]);

  return (
    <RVOWarning
      onClose={setWarningDisabledTrue}
      isOpen={showWarning}
      title={t("companyInfo.notSuiteableFor", { what: roleLabel })}
    >
      <Typography variant="body1">
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t("companyInfo.currentRoleNotRecommended", {
              beschlussName: vorlage?.kurzBezeichnung,
              roleName: roleLabel,
            }),
          }}
        />
      </Typography>

      <br />

      <Typography variant="body1">
        {t("companyInfo.needAdjustmentGetAdvise")}
      </Typography>

      <Box className={classes.buttonWrapper}>
        <Button onClick={setWarningDisabledTrue} variant="text">
          {t("general.ignore")}
        </Button>

        <Button onClick={adjustOrgan} variant={"outlined"}>
          {t("companyInfo.adjustOrgan")}
        </Button>
      </Box>
    </RVOWarning>
  );
};

export default RVORoleWarning;
