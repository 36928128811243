import { createStyles, makeStyles } from "@material-ui/core";
import { beschlussGeneratorMobileBreakpoint } from "../BeschlussGeneratorViewModel";
import { beschlussPaperStyles } from "./../BeschlussGeneratorStyles";

export const useBeschlussPreviewOnMobileStyles = makeStyles((theme) =>
  createStyles({
    previewCard: {
      display: "block",
      padding: theme.spacing(0),
      height: "100%",
      [theme.breakpoints.down(beschlussGeneratorMobileBreakpoint)]: {
        display: "flex",
        flexDirection: "column",
        alignContent: "space-between",
      },
    },
    previewCardContent: {
      padding: `${theme.spacing(0)} !important`,
    },
    beschlussPaper: {
      ...beschlussPaperStyles(theme),
    },
    previewModal: {
      zIndex: 1100,
    },
  })
);
