import { createStyles, makeStyles } from "@material-ui/core";

export const useBeschlussInputStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {},
    stepper: {
      padding: 0,
    },
    arrow: {
      transform: "rotate(180deg) scale(2) translateX(30%)",
      transformOrigin: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    stepLabel: {
      "& button": {
        width: "100%",
      },
    },
    stepButton: {},
    actionsContainer: {
      marginTop: theme.spacing(1),
      display: "flex",
      justifyContent: "flex-end",
    },
    warning: {
      marginBottom: theme.spacing(2),
    },
  })
);
