import { createStyles, makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const pushWarningToTheRight: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const useRVOSelectStyles = makeStyles(() =>
  createStyles({
    optionWrapper: { ...pushWarningToTheRight },
    selectWrapper: {
      "& .MuiSelect-root": {
        ...pushWarningToTheRight,
      },
    },
  })
);
