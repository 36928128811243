import { createStyles, makeStyles } from "@material-ui/core";

export const useTemplateVariableInputStyles = makeStyles(() =>
  createStyles({
    textField: {},
    wrapper: {
      overflowX: "hidden",
    },
  })
);
