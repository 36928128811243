import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  companyTypesLandingpage,
  isCompanyTypeLandingpage,
} from "../../../../../data/juristicalData/isCompanyTypeLandingpage";
import { rechtsformNames } from "../../../../../data/juristicalData/rechtsformNames";
import { CompanyTypeName } from "../../../../../data/vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { CompanyTypeLandingpage } from "../../../../../helpers/clientSide/constants";
import {
  useCompanyFirma,
  useCompanyRechtsform,
  useSetRechtsform,
} from "../../../../../state/companyInfo/companyInfoSlice";
import RVOSelect from "../../../../01-atoms/RVOSelect/RVOSelect";
import { inferRechtsformFromFirma } from "./inferRechtsformFromFirma";
import { useRechtsformIsInvalid } from "./useRechtsformIsInvalid";

// We use this value to see if the user has used the input.
const userHasNotTouchedThis = null;

export const BeschlussInputWhoRechtsform = (): JSX.Element => {
  const { t } = useTranslation();

  const rechtsformIsInvalid = useRechtsformIsInvalid();

  const rechtsform = useCompanyRechtsform();
  const setRechtsform = useSetRechtsform();

  const rechtsformLabel = t("companyInfo.rechtsform");

  const firma = useCompanyFirma();

  const [chosenRechtsform, setChosenRechtsform] = useState<
    typeof userHasNotTouchedThis | CompanyTypeName | ""
  >(
    /*
     * initialize the "chosen" value from the store.
     *
     * This means even a once "inferred" value becomes "chosen"
     *  as soon as the user comes back to this step.
     */
    rechtsform
  );

  const [inferredRechtsform, setInferredRechtsform] =
    useState<CompanyTypeName | null>(null);

  // Whenever the user types, we try to infer the Rechtsform from it.
  useEffect(() => {
    const inferred = inferRechtsformFromFirma(firma);
    const isValid = inferred && isCompanyTypeLandingpage(inferred);
    setInferredRechtsform(isValid ? inferred : null);
  }, [firma]);

  // If the user has chosen something deliberately, ignore the inferred value.
  useEffect(() => {
    if (chosenRechtsform !== userHasNotTouchedThis) {
      setRechtsform(chosenRechtsform || null);
    } else {
      setRechtsform(inferredRechtsform);
    }
  }, [chosenRechtsform, inferredRechtsform, setRechtsform]);

  return (
    <RVOSelect
      value={rechtsform}
      label={rechtsformLabel}
      options={companyTypesLandingpage}
      renderOption={(opt) => rechtsformNames[opt as CompanyTypeLandingpage]}
      onChange={setChosenRechtsform}
      emptyItemLabel={t("general.beschlussCoating.header.chooseRechtsform")}
      itemHasWarning={rechtsformIsInvalid}
    />
  );
};
