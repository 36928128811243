import RVOModal from "../RVOModal/RVOModal";
import { RVOModalLevel, RVOModalProps } from "../RVOModal/RVOModalInterfaces";

/**
 * A visual full-screen warning.
 *
 * Should be shown when the user does something dangerous.
 */
export const RVOWarning = (
  props: Omit<RVOModalProps, "level">
): JSX.Element | null => (
  <RVOModal {...{ ...props, level: RVOModalLevel.Warning }} />
);
