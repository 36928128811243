import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelectedVotingMode } from "../../../../../state/votingMode/votingModeSlice";
import {
  BeschlussInputStepHook,
  StepStatus,
} from "../BeschlussInputInterfaces";
import BeschlussInputVotingMode from "../BeschlussInputVotingMode/BeschlussInputVotingMode";

export const useBeschlussInputVotingModeStep: BeschlussInputStepHook = () => {
  const { t } = useTranslation();
  const votingMode = useSelectedVotingMode();

  const status = useMemo(
    // As soon as a voting mode is chosen, we're done here.
    () => (votingMode ? StepStatus.Complete : StepStatus.Open),
    [votingMode]
  );

  const label = useMemo(() => {
    const theLabel = t("votingMode.label");
    return status !== StepStatus.Open && votingMode
      ? `${theLabel}: ${votingMode.label(t)}`
      : theLabel;
  }, [status, t, votingMode]);

  const strings = t("inputSteps.votingMode", { returnObjects: true });

  return useMemo(
    () => ({
      description: strings.description,
      callToAction: strings.callToAction,
      incompleteWarning: strings.incompleteWarning,
      label,
      status,
      component: BeschlussInputVotingMode,
    }),
    [
      label,
      status,
      strings.callToAction,
      strings.description,
      strings.incompleteWarning,
    ]
  );
};
