import { Box, NoSsr } from "@material-ui/core";
import { useSelectedVotingMode } from "../../../../state/votingMode/votingModeSlice";
import RVOCoatingWrapper from "../../../03-organisms/RVOCoatingWrapper/RVOCoatingWrapper";
import AdditionalSignature from "../AdditionalSignature/AdditionalSignature";
import { StepStatus } from "../BeschlussInput/BeschlussInputInterfaces";
import { useBeschlussInputVotingModeStep } from "../BeschlussInput/stepHelpers/useBeschlussInputVotingModeStep";
import { useBeschlussInputWhoStep } from "../BeschlussInput/stepHelpers/useBeschlussInputWhoStep";
import { useBeschlussCoatingFooterStyles } from "./BeschlussCoatingHeader/BeschlussCoatingFooterStyles";

/**
 * 🚨 This is currently 95 % the same code as the BeschlussCoatingHeader.
 *
 *    If this stays the same, and the two don't develop different requirements, merge them!
 */
const BeschlussCoatingFooter = (): JSX.Element => {
  const votingMode = useSelectedVotingMode();
  const { status: whoStatus } = useBeschlussInputWhoStep();
  const { status: modeStatus } = useBeschlussInputVotingModeStep();
  const classes = useBeschlussCoatingFooterStyles();

  const ready =
    whoStatus === StepStatus.Complete && modeStatus === StepStatus.Complete;

  //  Note that ready should imply votingMode, only TS does not know.
  return (
    <NoSsr>
      {ready && votingMode ? (
        <Box component="section" className={classes.votingModeWrapper}>
          <votingMode.CoatingFooter />

          <AdditionalSignature />
        </Box>
      ) : (
        <Box className={classes.marginCoatingWrapper}>
          <RVOCoatingWrapper />
        </Box>
      )}
    </NoSsr>
  );
};

export default BeschlussCoatingFooter;
