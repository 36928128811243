import { Box } from "@material-ui/core";
import { clsx } from "clsx";
import { useContext } from "react";
import { LegacyTemplateLandingpage } from "../../../../helpers/createVorlageLandingpage";
import BeschlussCoatingFooter from "../BeschlussCoating/BeschlussCoatingFooter";
import BeschlussCoatingHeader from "../BeschlussCoating/BeschlussCoatingHeader/BeschlussCoatingHeader";
import {
  BeschlussGeneratorState,
  EphemeralUserInputContext,
} from "../ephemeralUserInput/EphemeralUserInputContext";
import { BeschlussContentRendered } from "./BeschlussContentRendered";
import { useBeschlussPreviewStyles } from "./BeschlussPreviewStyles";

interface BeschlussPreviewProps {
  beschlussElement?: React.MutableRefObject<HTMLDivElement | undefined>;
  vorlage: LegacyTemplateLandingpage;
}

/**
 * Display a preview of the current Beschluss to the user.
 *
 * It renders:
 *  - the overlay showing when the user sees this for the first time
 *  - the "coating" components wrapping the actual resolution "Antrag"
 *  - the inner "Antrag" with all variables
 *
 */
const BeschlussPreview = ({
  beschlussElement,
  vorlage,
}: BeschlussPreviewProps): JSX.Element => {
  const classes = useBeschlussPreviewStyles();

  const { generatorState } = useContext(EphemeralUserInputContext);

  const shouldHyphenate = generatorState === BeschlussGeneratorState.UserInput;
  const isRendering = generatorState !== BeschlussGeneratorState.UserInput;

  return (
    /*
     * By default, the Box types do not accept a `ref` – although it's working just fine.
     * Fix according to https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360
     */
    <Box
      className={clsx(
        classes.wrapper,
        // IMPORTANT! do not delete this class, otherwise we might expose decision data to our posthog instance
        "ph-no-capture"
      )}
      {...{ ref: beschlussElement }}
    >
      <BeschlussCoatingHeader />

      <BeschlussContentRendered
        shouldHyphenate={shouldHyphenate}
        vorlage={vorlage}
        className={clsx(
          classes.beschlussContent,
          isRendering && classes.beschlussContentPdfOutput
        )}
      />

      <BeschlussCoatingFooter />
    </Box>
  );
};

export default BeschlussPreview;
