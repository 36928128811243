import { createStyles, makeStyles } from "@material-ui/core";
import { fontWeightBold } from "../../../../layouts/theme";

export const useBeschlussNotarizeButtonStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {},
    badge: {
      "& span.MuiBadge-badge": {
        backgroundColor: theme.palette.warning.main,
        fontWeight: fontWeightBold,
      },
    },
  })
);
