import { LinearProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { isGeneratingFakeTimeout } from "../../04-templates/BeschlussGenerator/BeschlussGeneratorViewModel";

export const BeschlussFinishedProgressBar = (): JSX.Element => {
  const [progress, setProgress] = useState(0);
  const increment = 100 / isGeneratingFakeTimeout;

  useEffect(() => {
    const startTime = Date.now();
    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      if (elapsedTime >= isGeneratingFakeTimeout) {
        setProgress(100);
        clearInterval(timer);
      } else {
        setProgress(elapsedTime * increment);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [increment]);
  return (
    <LinearProgress variant="buffer" valueBuffer={progress} value={progress} />
  );
};
