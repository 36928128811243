import {
  Box,
  Button,
  Popper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { isIOSDevice } from "@nvon/baseline";
import { NWONLottieWrapper, useId, useTrigger } from "@nvon/react-toolbox";
import { clsx } from "clsx";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import arrowLottie from "./../../../../resources/lottie-jsons/drawn-arrow.json";
import { BeschlussInputProps, StepStatus } from "./BeschlussInputInterfaces";
import { useBeschlussInputStyles } from "./BeschlussInputStyles";
import { useBeschlussInputViewModel } from "./BeschlussInputViewModel";

/**
 * Wrap individual input steps – currently in a vertical stepper.
 */
const BeschlussInput = (props: BeschlussInputProps): JSX.Element => {
  const classes = useBeschlussInputStyles();
  const { t } = useTranslation();

  const {
    activeStep,
    goToNextStep,
    popperAnchor,
    isPopperShowing,
    goToPreviousStep,
    goToStepNumber,
    inputSteps,
  } = useBeschlussInputViewModel(props);

  const id = useId();

  const { trigger: triggerPlaying, activateTrigger: playAnimation } =
    useTrigger();

  useEffect(() => {
    playAnimation();
  }, [playAnimation]);

  return (
    <Box className={clsx(classes.wrapper, props.className)}>
      {isIOSDevice() && (
        <Alert severity="warning" className={classes.warning}>
          {t("beschlussInputMeta.iosWarning")}
        </Alert>
      )}

      <Popper
        id={id}
        open={isPopperShowing}
        anchorEl={popperAnchor}
        placement="left"
      >
        <NWONLottieWrapper
          lottieFileJson={arrowLottie}
          triggerPlaying={triggerPlaying}
        />
      </Popper>

      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        orientation="vertical"
      >
        {inputSteps.map((step, index, all) => {
          const label = step.label;
          const isLastStep = index === all.length - 1;
          const isActive = index === activeStep;
          const completed = step.status === StepStatus.Complete;

          // The number of steps will be fixed, so this is okay.
          const key = `step-${index}`;

          return (
            <Step key={key} className={isActive ? "isActive" : ""}>
              <StepLabel
                className={classes.stepLabel}
                StepIconProps={{
                  completed,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => goToStepNumber(index)}
                >
                  {label}
                </Button>
              </StepLabel>

              <StepContent>
                <step.component />

                {!isLastStep && (
                  <div className={classes.actionsContainer}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={goToPreviousStep}
                      className={classes.stepButton}
                    >
                      {t("general.navigation.back")}
                    </Button>

                    <Button
                      variant={completed ? "contained" : "outlined"}
                      color="primary"
                      onClick={goToNextStep}
                      className={classes.stepButton}
                    >
                      {t("general.navigation.forth")}
                    </Button>
                  </div>
                )}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default BeschlussInput;
