import { alpha, createStyles, makeStyles } from "@material-ui/core";

export const useBeschlussInputAntragStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      "& .rvoHelpButton button": {
        backgroundColor: alpha(theme.palette.common.white, 0.9),
      },
    },
    wrapperEditVorlage: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      "& .MuiButton-label p": {
        textAlign: "right",
      },
    },
  })
);
