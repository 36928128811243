import { createStyles, makeStyles } from "@material-ui/core";
import { beschlussGeneratorMobileBreakpoint } from "../BeschlussGeneratorViewModel";

export const useBeschlussGeneratorMobilePreviewButtonStyles = makeStyles(
  (theme) =>
    createStyles({
      previewIcon: {
        display: "none",
        position: "fixed",
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        padding: theme.spacing(1.15),
        zIndex: 1101,
        width: "60px",
        height: "60px",
        [theme.breakpoints.down(beschlussGeneratorMobileBreakpoint)]: {
          display: "block",
        },

        "& button": {
          marginBottom: theme.spacing(1),
        },
      },
    })
);
