import { createStyles, makeStyles } from "@material-ui/core";

export const useBeschlussHandelsregisterpflichtigStepperStyles = makeStyles(
  () =>
    createStyles({
      stepLabel: {
        "& .MuiStepLabel-label": {
          marginBottom: "0!important",
        },
      },
    })
);
