import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { rethrow } from "@nvon/baseline";
import { useBooleanState } from "@nvon/react-toolbox";
import delay from "delay";
import { noop } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import { VorlageKategorie } from "../../../data/vorlagen/vorlagenShared/VorlageInterface";
import { minimumCreateDocumentDuration } from "../../../helpers/clientSide/constants";
import { generatePdfFromElement } from "../../../helpers/clientSide/generatePdfFromElement";
import { LegacyTemplateLandingpage } from "../../../helpers/createVorlageLandingpage";
import {
  useSetCurrentVorlage,
  useSetShowVorlagenPicker,
  useShowVorlagenPicker,
} from "../../../state/beschlussVorlage/beschlussVorlageSlice";
import {
  useResetVotingModeTrigger,
  useVotingModeChangeTrigger,
} from "../../../state/votingMode/votingModeSlice";
import {
  RVOTrackingEvent,
  trackEvent,
} from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import { useClosePickerOnVorlagenChange } from "../../03-organisms/VorlagenPicker/useClosePickerOnVorlagenChange";
import {
  BeschlussGeneratorProps,
  BeschlussGeneratorViewModel,
} from "./BeschlussGeneratorInterfaces";
import { BeschlussGeneratorState } from "./ephemeralUserInput/EphemeralUserInputContext";
import { useEphemeralUserInputStateProvider } from "./ephemeralUserInput/useEphemeralUserInputStateProvider";
import { useVorlagenSeo } from "./useVorlagenSeo";

export const fileNameFromVorlage = (
  vorlage: LegacyTemplateLandingpage
): string => {
  return `Beschluss – ${vorlage.kurzBezeichnung}`;
};

export const beschlussGeneratorMobileBreakpoint: Breakpoint = "sm";
export const beschlussGeneratorDesktopBreakpoint: Breakpoint = "md";

export const isGeneratingFakeTimeout = 3600;

export const useBeschlussGeneratorViewModel = (
  props: BeschlussGeneratorProps
): BeschlussGeneratorViewModel => {
  const { pageContext: vorlage } = props;
  const ephemeralUserInputState = useEphemeralUserInputStateProvider();
  const resetVotingModeTrigger = useResetVotingModeTrigger();
  const { generatorState, setGeneratorState, externallyFocusStep } =
    ephemeralUserInputState;

  useEffect(() => {
    return () => {
      resetVotingModeTrigger();
      externallyFocusStep(0);
    };
  }, [externallyFocusStep, resetVotingModeTrigger]);

  const setCurrentVorlage = useSetCurrentVorlage();
  const votingModeTrigger = useVotingModeChangeTrigger();

  useClosePickerOnVorlagenChange();

  /*
   * This component is passed the current vorlage as a prop,
   *  and sets it in the store for child elements.
   */
  useEffect(() => {
    setCurrentVorlage(vorlage);
  }, [setCurrentVorlage, vorlage]);

  /*
   * The function to save is only available once the pdf has been generated.
   * Wrapped in an object, because handling plain functions in state is weird.
   */
  const [savePdf, setSavePdf] = useState<{
    saveFn: VoidFunction;
  }>({
    saveFn: noop,
  });

  const printPdf = () => {
    if (typeof window !== "undefined") {
      window.print();
    }
  };

  const beschlussElement = useRef<HTMLDivElement>();

  const isGenerating = generatorState === BeschlussGeneratorState.Generating;

  const generatePdf = useCallback(() => {
    if (!beschlussElement.current) {
      return;
    }
    const fileName = fileNameFromVorlage(vorlage);

    setGeneratorState(BeschlussGeneratorState.Generating);
    Promise.all([
      generatePdfFromElement(beschlussElement.current, fileName),
      delay(minimumCreateDocumentDuration),
    ])
      .then(([saveFn]) => {
        setTimeout(() => {
          setGeneratorState(BeschlussGeneratorState.DocumentsReady);
          setSavePdf({
            saveFn: () => {
              saveFn().catch(rethrow);
            },
          });
        }, isGeneratingFakeTimeout);
      })
      .catch(rethrow);
  }, [setGeneratorState, vorlage]);

  const handleGeneratePdfClick = useCallback(() => {
    generatePdf();
    trackEvent(RVOTrackingEvent.GeneratePdfClicked);
  }, [generatePdf]);

  const isDrawerOpen = generatorState !== BeschlussGeneratorState.UserInput;

  const hideDrawer = () => setGeneratorState(BeschlussGeneratorState.UserInput);

  const {
    state: isMobilePreviewShowing,
    toggle: toggleIsMobilePreviewShowing,
  } = useBooleanState(false);

  const isPickerShowing = useShowVorlagenPicker();
  const setIsPickerShowing = useSetShowVorlagenPicker();

  const setIsPickerShowingTrue = useCallback(
    () => setIsPickerShowing(true),
    [setIsPickerShowing]
  );
  const setIsPickerShowingFalse = useCallback(
    () => setIsPickerShowing(false),
    [setIsPickerShowing]
  );

  const focusVotingMode = useCallback(
    () => externallyFocusStep(1),
    [externallyFocusStep]
  );

  useEffect(() => {
    if (votingModeTrigger) {
      focusVotingMode();
    }
  }, [focusVotingMode, votingModeTrigger]);

  const [isEmployeeParticipationShowing, setIsEmployeeParticipationShowing] =
    useState(false);

  const showEmployeeParticipationModal = () => {
    setIsEmployeeParticipationShowing(true);
  };

  const closeEmployeeParticipationModal = () => {
    setIsEmployeeParticipationShowing(false);
  };

  useEffect(() => {
    if (vorlage.isEmployeeParticipationTemplate) {
      showEmployeeParticipationModal();
    }
    if (!vorlage.isEmployeeParticipationTemplate) {
      closeEmployeeParticipationModal();
    }
  }, [vorlage.isEmployeeParticipationTemplate, vorlage.kurzBezeichnung]);

  const [isCscTemplateShowing, setIsCscTemplateShowing] = useState(false);

  const showCscTemplateModal = () => {
    setIsCscTemplateShowing(true);
  };

  const closeCscTemplateModal = () => {
    setIsCscTemplateShowing(false);
  };

  useEffect(() => {
    if (vorlage.vorlageKategorie === VorlageKategorie.anbauvereinigung) {
      showCscTemplateModal();
    }
    if (vorlage.vorlageKategorie !== VorlageKategorie.anbauvereinigung) {
      closeCscTemplateModal();
    }
  }, [vorlage.vorlageKategorie]);

  return {
    isCscTemplateShowing,
    showCscTemplateModal,
    closeCscTemplateModal,
    isEmployeeParticipationShowing,
    showEmployeeParticipationModal,
    closeEmployeeParticipationModal,
    isMobilePreviewShowing,
    showPickerOverlay: setIsPickerShowingTrue,
    isPickerShowing,
    onPickerClose: setIsPickerShowingFalse,
    focusVotingMode,
    toggleIsMobilePreviewShowing,
    seo: useVorlagenSeo(vorlage),
    isDrawerOpen,
    hideDrawer,
    handleGeneratePdfClick,
    vorlage,
    isGenerating,
    savePdf: savePdf.saveFn,
    printPdf,
    beschlussElement,
    ephemeralUserInputState,
  };
};
