import { BeschlussInputStep, StepStatus } from "../BeschlussInputInterfaces";

// We use this number to have the stepper collapsed
export const stepperCollapsedIndex = -1;

export const getFirstIncompleteStepIndex = (
  inputSteps: BeschlussInputStep[]
): number =>
  inputSteps.findIndex((step) => step.status !== StepStatus.Complete);

export const allStepsAreCompleted = (
  inputSteps: BeschlussInputStep[]
): boolean => getFirstIncompleteStepIndex(inputSteps) === -1;
