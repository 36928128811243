import { useEffect } from "react";
import {
  useCurrentVorlage,
  useSetShowVorlagenPicker,
} from "../../../state/beschlussVorlage/beschlussVorlageSlice";

/**
 * Effect hook to close the vorlagen picker when the selected vorlage changes.
 */
export const useClosePickerOnVorlagenChange = (): void => {
  const vorlage = useCurrentVorlage();

  const setPickerShowing = useSetShowVorlagenPicker();

  useEffect(() => {
    setPickerShowing(false);
  }, [setPickerShowing, vorlage]);
};
