import { CompanyTypeName } from "../../../../../data/vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { CompanyInfoState } from "../../../../../state/companyInfo/companyInfoSlice";

/**
 * Try to guess the right Rechtsform from the given firma.
 *
 * This will definitely produce *false guesses* at some point.
 * Not too bad, since the user can always correct manually.
 *
 *  Code-wise, probably not the cleanest way to do it.
 *  But we got this from Hubi, so let's use it for now.
 */
export const inferRechtsformFromFirma = (
  firma: CompanyInfoState["firma"]
): CompanyTypeName | null => {
  const name = firma.toLocaleLowerCase();

  if (name.includes(" se")) {
    return CompanyTypeName.SeDe;
  }

  if (
    name.includes("gmbh") &&
    !name.includes("co") &&
    !name.includes("kg") &&
    !name.includes("ggmbh")
  ) {
    return CompanyTypeName.GmbHDe;
  }

  if (name.includes("gmbh") && name.includes("co")) {
    return CompanyTypeName.GmbHCoKgDe;
  }

  if (
    (name.includes(" ug") || name.includes("unternehmergesellschaft")) &&
    !name.includes("co") &&
    !name.includes("kg") &&
    !name.includes("gUG")
  ) {
    return CompanyTypeName.UgDe;
  }

  if (
    (name.includes(" ug") || name.includes("unternehmergesellschaft")) &&
    name.includes("co")
  ) {
    return CompanyTypeName.UgCoKgDe;
  }

  if (
    (name.includes("kg") || name.includes("kommanditgesellschaft")) &&
    !(
      name.includes("gmbh") ||
      name.includes(" ug") ||
      name.includes("unternehmergesellschaft") ||
      name.includes("kgaa") ||
      name.includes("auf aktien")
    )
  ) {
    return CompanyTypeName.KgDe;
  }

  if (name.includes("ohg") || name.includes("offene handelsgesell")) {
    return CompanyTypeName.OHgDe;
  }

  if (
    name.includes("gbr") ||
    (name.includes("gesellschaft") &&
      name.includes("bürgerlich") &&
      name.includes("recht"))
  ) {
    return CompanyTypeName.GbRDe;
  }

  if (name.includes("part") && !name.includes("mbb")) {
    return CompanyTypeName.PartGDe;
  }

  if (name.includes("mbb") || name.includes("berufshaftung")) {
    return CompanyTypeName.PartGmbBDe;
  }

  if (name.includes("eV") || name.includes("e.V.")) {
    return CompanyTypeName.EVDe;
  }

  if (
    name.includes("aör") ||
    name.includes("adör") ||
    name.includes("anstalt des öffentlichen rechts")
  ) {
    return CompanyTypeName.ArDe;
  }

  if (name.includes("eg") || name.includes("genossenschaft")) {
    return CompanyTypeName.EGDe;
  }

  if (
    name.includes("bruchteil") ||
    name.includes("grundstück") ||
    name.includes("eigentum")
  ) {
    return CompanyTypeName.BruchteilsgemeinschaftDe;
  }

  if (name.includes("erben") || name.includes("nachlass")) {
    return CompanyTypeName.ErbengemDe;
  }

  if (name.includes("ewiv")) {
    return CompanyTypeName.EwivDe;
  }

  if (name.includes("kgaa") || name.includes("auf aktien")) {
    return CompanyTypeName.KgaADe;
  }

  if (
    name.includes("nev") ||
    name.includes("n.e.v.") ||
    name.includes("n. e. v.") ||
    name.includes("gewerkschaft") ||
    name.includes("partei") ||
    name.includes("burschenschaft") ||
    name.includes("verbindung") ||
    name.includes("städtetag")
  ) {
    return CompanyTypeName.NeVDe;
  }

  if (name.includes("sce")) {
    return CompanyTypeName.SceDe;
  }

  if (name.includes("stiftung")) {
    return CompanyTypeName.StiftungDe;
  }

  if (
    name.includes("vvag") ||
    name.includes("a. g.") ||
    name.includes("a.g.")
  ) {
    return CompanyTypeName.VvaGDe;
  }

  if (
    (name.includes("ev") ||
      name.includes("verein") ||
      name.includes("e.v.") ||
      name.includes("e. v.")) &&
    !name.includes("a. g.") &&
    !name.includes("a.g.")
  ) {
    return CompanyTypeName.EVDe;
  }

  if (
    name.includes("weg") ||
    name.includes("wohnungseigentümergemeinschaft") ||
    name.includes("w e g") ||
    name.includes("w.e.g.") ||
    name.includes("w. e. g.")
  ) {
    return CompanyTypeName.WegDe;
  }

  return null;
};
