import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import { clsx } from "clsx";
import { animated } from "react-spring";
import { Widget } from "../../../../common-interfaces/VorlageInterface";
import RVOHelpButton from "../../../02-molecules/RVOHelpButton/RVOHelpButton";
import { TemplateVariableInputProps } from "./TemplateVariableInputInterfaces";
import { useTemplateVariableInputStyles } from "./TemplateVariableInputStyles";
import { useTemplateVariableInputViewModel } from "./TemplateVariableInputViewModel";

const multilineProps: TextFieldProps = {
  minRows: 3,
  multiline: true,
};

/**
 * A single input field for a template variable.
 * This is where the user enters data.
 */
const TemplateVariableInput = (
  props: TemplateVariableInputProps
): JSX.Element => {
  /*
   * Passed down from the parent, but ultimately coming from redux.
   * Could theoretically be retrieved directly from store, same same.
   */
  const { variable, value, onChange } = props;

  const { animatedDivStyles, inputRef, key, onBlur, onFocus } =
    useTemplateVariableInputViewModel(props);

  const classes = useTemplateVariableInputStyles();

  const isMultiline = variable.variableType?.widget === Widget.textarea;

  return (
    <animated.div style={animatedDivStyles} className={classes.wrapper}>
      <TextField
        variant="outlined"
        inputRef={inputRef}
        className={clsx(props.className, classes.textField)}
        key={key}
        {...(isMultiline ? multilineProps : {})}
        placeholder={variable.name}
        label={variable.name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <RVOHelpButton
                helpTitle={variable.name}
                helpText={variable.description}
              />
            </InputAdornment>
          ),
        }}
        fullWidth={true}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        autoFocus={props.shouldAutoFocus}
      />
    </animated.div>
  );
};

export default TemplateVariableInput;
