import { useBooleanState } from "@nvon/react-toolbox";
import { RocketEmoji } from "../../../../helpers/clientSide/emojis";
import { RVOAppPromotionContent } from "../../../02-molecules/RVOAppPromotionContent/RVOAppPromotionContent";
import RVOModal from "../../../02-molecules/RVOModal/RVOModal";
import { RVOModalSize } from "../../../02-molecules/RVOModal/RVOModalInterfaces";
import { useBeschlussGeneratorAppPromotionOverlayStyles } from "./BeschlussGeneratorAppPromotionOverlayStyles";

export const BeschlussGeneratorAppPromotionOverlay = (): JSX.Element => {
  const { state: isShowingOverlay, off: setClosed } = useBooleanState(true);
  const classes = useBeschlussGeneratorAppPromotionOverlayStyles();

  return (
    <RVOModal
      emoji={RocketEmoji}
      isOpen={isShowingOverlay}
      onClose={setClosed}
      size={RVOModalSize.Large}
      className={classes.dialog}
    >
      <RVOAppPromotionContent closeOverlayCallback={setClosed} />
    </RVOModal>
  );
};
