import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { mapBeschlussVorlageContent } from "../../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { fontFamilySerif } from "../../../../layouts/theme";
import RVOContainer from "../../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundGradient } from "../../../02-molecules/RVOContainer/RVOContainerInterfaces";
import { BeschlussPageHeaderProps } from "./BeschlussPageHeaderInterfaces";

export const BeschlussPageHeader = ({
  vorlage,
}: BeschlussPageHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const renderedDescription = useMemo(() => {
    return replaceHtmlWithFunctionComponent(
      vorlage.description,
      mapBeschlussVorlageContent(vorlage, true)
    );
  }, [vorlage]);
  const renderedImplementationNotes = useMemo(() => {
    return replaceHtmlWithFunctionComponent(
      vorlage.vollzugshinweise,
      mapBeschlussVorlageContent(vorlage, true)
    );
  }, [vorlage]);

  return (
    <RVOContainer
      fullWidth={true}
      background={RVOBackgroundGradient.SecondaryLightToMediumBlue}
      noPaddingTop={true}
    >
      <Typography
        style={{ margin: theme.spacing(2), textAlign: "center" }}
        component="div"
      >
        <Typography
          variant="subtitle1"
          align="center"
          style={{ color: theme.palette.primary.light, fontWeight: "bold" }}
        >
          {t("vorlagen.currentVorlage")}
        </Typography>

        <Typography
          component="h1"
          variant="h2"
          style={{
            margin: 0,
            textAlign: "center",
            fontFamily: fontFamilySerif,
            [theme.breakpoints.down("sm")]: {
              margin: theme.spacing(2, 0),
            },
          }}
        >
          {vorlage.kurzBezeichnung}
        </Typography>
      </Typography>

      <Box style={{ paddingBottom: theme.spacing(1) }}>
        <Accordion
          style={{
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMore
                style={{
                  color: theme.palette.primary.light,
                }}
              />
            }
            style={{
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: theme.palette.primary.light,
                }}
              >
                {t("general.description")}
              </Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails style={{ flexDirection: "column" }}>
            <Typography variant="body1">{renderedDescription}</Typography>

            {renderedImplementationNotes && (
              <>
                <Typography
                  variant="h5"
                  style={{ marginTop: theme.spacing(1) }}
                >
                  {t("general.implementationNotes")}
                </Typography>

                <Typography variant="body1">
                  {renderedImplementationNotes}
                </Typography>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </RVOContainer>
  );
};
