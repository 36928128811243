import { createStyles, makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import theme from "../../../../../layouts/theme";
export const stepExplainer = {
  color: theme.palette.grey[500],
};

export const verticalWrapper: CSSProperties = {
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  display: "flex",
  flexDirection: "row",
  columnGap: theme.spacing(1),
  alignItems: "center",
};

export const emoji = { fontSize: "1.4rem" };

export const useBeschlussInputWhoStyles = makeStyles(() =>
  createStyles({
    wrapper: {},
    stepExplainer: { ...stepExplainer },
    verticalWrapper: { ...verticalWrapper },
    emoji: { ...emoji },
  })
);
