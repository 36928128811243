import { createStyles, makeStyles } from "@material-ui/core";

export const useWarningStyles = makeStyles((theme) =>
  createStyles({
    buttonWrapper: {
      marginTop: theme.spacing(3),
      textAlign: "right",
      "& button": {
        marginLeft: theme.spacing(2),
      },
    },
  })
);
