import { useMemo } from "react";
import { BeschlussInputStep } from "../BeschlussInputInterfaces";
import { useBeschlussInputVotingModeStep } from "./useBeschlussInputVotingModeStep";
import { useBeschlussInputWhoStep } from "./useBeschlussInputWhoStep";

export const useFirstTwoBeschlussInputSteps = (): BeschlussInputStep[] => {
  const whoStep = useBeschlussInputWhoStep();
  const modeStep = useBeschlussInputVotingModeStep();
  const allSteps = useMemo(() => [whoStep, modeStep], [whoStep, modeStep]);

  return allSteps;
};
