import { useMemo } from "react";
import { BeschlussInputStep } from "../BeschlussInputInterfaces";
import { useBeschlussInputAntragStep } from "./useBeschlussInputAntragStep";
import { useBeschlussInputVotingModeStep } from "./useBeschlussInputVotingModeStep";
import { useBeschlussInputWhoStep } from "./useBeschlussInputWhoStep";

export const useAllBeschlussInputSteps = (): BeschlussInputStep[] => {
  const whoStep = useBeschlussInputWhoStep();
  const modeStep = useBeschlussInputVotingModeStep();
  const antragStep = useBeschlussInputAntragStep();

  const allSteps = useMemo(
    () => [whoStep, modeStep, antragStep],
    [whoStep, modeStep, antragStep]
  );

  return allSteps;
};
