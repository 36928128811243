import { Badge, Dialog } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CloseIcon from "@material-ui/icons/Close";
import { useBooleanState } from "@nvon/react-toolbox";
import { Button, IconButton } from "gatsby-material-ui-components";
import { useTranslation } from "react-i18next";
import {
  RVOTrackingEvent,
  trackEvent,
} from "../../../00-globals/TruendoGoogleTagManager/trackEvent";
import { BeschlussNotarizeButtonProps } from "./BeschlussNotarizeButtonInterfaces";
import { useBeschlussNotarizeButtonStyles } from "./BeschlussNotarizeButtonStyles";

const BeschlussNotarizeButton = (
  props: BeschlussNotarizeButtonProps
): JSX.Element => {
  const { t } = useTranslation();
  const classes = useBeschlussNotarizeButtonStyles();

  const {
    state: notarizeFormModalOpen,
    on: setNotarizeFormModalOpenTrue,
    off: setNotarizeFormModalOpenFalse,
  } = useBooleanState(false);

  const handleNotarizeFormClick = () => {
    trackEvent(RVOTrackingEvent.NotarizeFormOpened);
    setNotarizeFormModalOpenTrue();
  };

  return (
    <>
      <Badge
        badgeContent="NEU"
        style={{
          width: "100%",
        }}
        className={classes.badge}
      >
        <Button
          endIcon={<AccountBalanceIcon />}
          variant="outlined"
          className={classes.wrapper}
          disabled={props.disabled}
          onClick={handleNotarizeFormClick}
          fullWidth={true}
        >
          {t("general.notarizeItButton")}
        </Button>
      </Badge>

      <Dialog
        open={notarizeFormModalOpen}
        onClose={setNotarizeFormModalOpenFalse}
        fullScreen={true}
      >
        <iframe
          style={{
            height: "100%",
            width: "100%",
            border: 0,
          }}
          src="https://notarity.com/buchen/resolvio/"
        />

        <IconButton
          onClick={setNotarizeFormModalOpenFalse}
          style={{ position: "absolute", top: 30, right: 30 }}
        >
          <CloseIcon color="primary" />
        </IconButton>
      </Dialog>
    </>
  );
};

export default BeschlussNotarizeButton;
